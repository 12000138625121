// src/MantisBusiness/Notifications.jsx
import React, { useState } from "react";
import InboxNotification from "./InboxNotification";
import { Modal } from "reactstrap";
import "./styles/businessBackofficeStyles.scss";

function Notifications() {
  const [showNotifications, setShowNotifications] = useState(false);

  const toggleNotifications = () => {
      setShowNotifications(!showNotifications);
  };

  return (
    <div>
      <div className="notification-bell" onClick={toggleNotifications}>
        🔔
      </div>
      {showNotifications && (
        <Modal className="notifications-modal" isOpen={showNotifications} toggle={toggleNotifications}>
            <InboxNotification />
        </Modal>
      )}
    </div>
  );
}

export default Notifications;