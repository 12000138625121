import React, { useState, useEffect } from "react";
import { Spinner, Input, InputGroup } from "reactstrap";
import { connect } from "react-redux";
import { searchUser } from "../../ApiCalls/backoffice";
import { checkMantisBusinessUserPermission } from "../../utils/businessUtils";

const select = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
    user: state.auth.user,
  };
};

function ArchiveContextMenu(props) {
  const top = props.top;
  const left = props.left;
  const elementType = props.elementType;
  const hasEditPermission = checkMantisBusinessUserPermission(
    props.user,
    elementType === "FOLDER" ? "EDIT_FOLDERS" : "EDIT_FILES"
  );
  const hasDeletePermission = checkMantisBusinessUserPermission(
    props.user,
    elementType === "FOLDER" ? "DELETE_FOLDERS" : "DELETE_FILES"
  );

  return (
    <>
      {!hasEditPermission && !hasDeletePermission ? (
        <></>
      ) : (
        <div
          style={{
            position: "absolute",
            top: top,
            left: left - 50,
            width: "200px",
            backgroundColor: "white",
            zIndex: "1000",
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.5)",
            borderRadius: "5px",
          }}
        >
          <div
            style={{
              padding: "10px",
              fontSize: "14px",
              fontFamily: "Poppins, sans-serif",
            }}
          >
            {hasEditPermission && (
              <div
                style={{
                  padding: "5px",
                  cursor: "pointer",
                  ":hover": {
                    backgroundColor: "red",
                  },
                }}
                onClick={() => {
                  props.handleNameChange();
                }}
              >
                <i
                  className="fas fa-edit"
                  style={{ marginRight: "5px", color: "black" }}
                ></i>
                Editar
              </div>
            )}
            {hasDeletePermission && (
              <div
                style={{
                  padding: "5px",
                  cursor: "pointer",
                  ":hover": {
                    backgroundColor: "red",
                  },
                }}
                onClick={() => {
                  props.handleDeleteElement();
                }}
              >
                <i
                  className="fas fa-trash"
                  style={{ marginRight: "5px", color: "black" }}
                ></i>
                Eliminar
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default connect(select)(ArchiveContextMenu);
