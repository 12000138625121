function checkMantisBusinessUserPermission(user, permission) {
  if (!user) {
    return false;
  }
  if (user.mainUser) {
    return true;
  }
  if (user.permissions) {
    for (let i = 0; i < user.permissions.length; i++) {
      if (
        user.permissions[i].permission === permission ||
        user.permissions[i] === permission
      ) {
        return true;
      }
    }
  }
  return false;
}

module.exports = {
  checkMantisBusinessUserPermission
};
