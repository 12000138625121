import React, {useState, useRef, useEffect, useTransition} from "react";
import {Button} from "./components/ui/button";
import {Card} from "./components/ui/card";
import {Input} from "./components/ui/input";
import {ScrollArea} from "./components/ui/scroll-area";
import {Separator} from "./components/ui/separator";
import {motion, AnimatePresence} from "framer-motion";
import {backUrl} from "../utils/backURL";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle
} from "./components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "./components/ui/select";
import {
  FileText,
  Send,
  Upload,
  Plus,
  Save,
  Mail,
  Cloud,
  MessageSquare,
  Database,
  BarChart4,
  X,
  ChevronRight,
  Eye,
  Loader2,
  Edit,
  Trash,
  Minus
} from "lucide-react";
import Xarrow from "react-xarrows";
import {getMantisResponse} from "../ApiCalls/mantisIA";
import {uploadDocument} from "../ApiCalls/mantisDocuments";
import "./styles/styles.output.css";
import outlookLogo from "../Assets/img/icons/b2b/integrationLogos/outlookLogo.png";
import gmailLogo from "../Assets/img/icons/b2b/integrationLogos/gmailLogo.png";

const operatorMappping = {
  EQUAL_TO: "Equal to",
  DIFFERENT_THAN: "Different than",
  GREATER_THAN_OR_EQUAL_TO: "Greater or equal to",
  LESS_THAN_OR_EQUAL_TO: "Less or equal to",
  GREATER_THAN: "Greater than",
  LESS_THAN: "Less than",
  SUM: "Sum",
  SUBTRACT: "Subtract",
  MULTIPLY: "Multiply",
  DIVIDE: "Divide"
};
/* const operatorMappping = {
  EQUAL_TO: "Igual a",
  DIFFERENT_THAN: "Diferente a",
  GREATER_THAN_OR_EQUAL_TO: "Mayor o igual a",
  LESS_THAN_OR_EQUAL_TO: "Menor o igual a",
  GREATER_THAN: "Mayor a",
  LESS_THAN: "Menor a",
  SUM: "Suma",
  SUBTRACT: "Resta",
  MULTIPLY: "Multiplicación",
  DIVIDE: "División",
}; */

const translations = {
  en: {
    welcome: {
      title: "Welcome to Mantis",
      description:
        "To get started, upload sample documents that you would like to reconcile.",
      uploadButton: "Upload Files"
    },
    chat: {
      inputPlaceholder: {
        withFile: "Ask to extract info from the document...",
        withoutFile: "Write a conditional or calculated field..."
      },
      sendButton: "Send",
      analyzing: "Analyzing...",
      uploadError: "There was an error uploading the files. Please try again.",
      newColumn: "New Column"
    },
    filePreview: {
      title: "File Preview",
      notAvailable: "Preview not available for this file type."
    },
    flowCreation: {
      creating: "Creating...",
      create: "Create Flow",
      deleteFieldConfirm: "Are you sure you want to delete this field?",
      tableNamePlaceholder: "Enter table name",
      editTable: "Edit table",
      deleteTable: "Delete table",
      deleteField: "Delete field"
    },
    errors: {
      processingError:
        "There was an error processing the message. Please try again.",
      networkError: "There was a network error. Please try again."
    }
  },
  es: {
    welcome: {
      title: "Bienvenido a Mantis",
      description:
        "Para comenzar, sube los documentos de muestra que te gustaría conciliar.",
      uploadButton: "Subir Archivos"
    },
    chat: {
      inputPlaceholder: {
        withFile: "Solicita extraer información del documento...",
        withoutFile: "Escribe un campo condicional o calculado..."
      },
      sendButton: "Enviar",
      analyzing: "Analizando...",
      uploadError:
        "Hubo un error al subir los archivos. Por favor, inténtalo de nuevo.",
      newColumn: "Nueva Columna"
    },
    filePreview: {
      title: "Vista Previa del Archivo",
      notAvailable: "Vista previa no disponible para este tipo de archivo."
    },
    flowCreation: {
      creating: "Creando...",
      create: "Crear Flujo",
      deleteTableConfirm: "¿Estás seguro de que deseas eliminar esta fila?",
      tableNamePlaceholder: "Ingresa el nombre de la tabla",
      editTable: "Editar tabla",
      deleteTable: "Eliminar tabla",
      deleteField: "Eliminar campo"
    },
    errors: {
      processingError:
        "Hubo un error al procesar el mensaje. Por favor, inténtalo de nuevo.",
      networkError: "Hubo un error de red. Por favor, inténtalo de nuevo."
    }
  },
  pt: {
    welcome: {
      title: "Bem-vindo ao Mantis",
      description:
        "Para começar, faça upload dos documentos de amostra que você gostaria de conciliar.",
      uploadButton: "Enviar Arquivos"
    },
    chat: {
      inputPlaceholder: {
        withFile: "Peça para extrair informações do documento...",
        withoutFile: "Escreva um campo condicional ou calculado..."
      },
      sendButton: "Enviar",
      analyzing: "Analisando...",
      uploadError:
        "Houve um erro ao enviar os arquivos. Por favor, tente novamente.",
      newColumn: "Nova Coluna"
    },
    filePreview: {
      title: "Visualização do Arquivo",
      notAvailable: "Visualização não disponível para este tipo de arquivo."
    },
    flowCreation: {
      creating: "Criando...",
      create: "Criar Fluxo",
      deleteTableConfirm: "Você tem certeza de que deseja excluir esta tabela?",
      tableNamePlaceholder: "Digite o novo nome para a tabela",
      editTable: "Editar tabela",
      deleteTable: "Excluir tabela",
      deleteField: "Excluir campo"
    },
    errors: {
      processingError:
        "Houve um erro ao processar a mensagem. Por favor, tente novamente.",
      networkError: "Houve um erro de rede. Por favor, tente novamente."
    }
  }
};

const cleanNumber = (value) => {
  if (typeof value === "string") {
    // Elimina puntos de miles y reemplaza la coma decimal por punto
    return Number(value.replace(/\./g, "").replace(",", "."));
  }
  return Number(value);
};

const AnimatedButton = motion(Button);

const CustomAnimatedButton = ({children, className, ...props}) => {
  const MotionDiv = motion.div;

  return (
    <MotionDiv
      className={`inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 ${className}`}
      whileHover={{scale: 1.05}}
      whileTap={{scale: 0.95}}
      as="button"
      {...props}
    >
      {children}
    </MotionDiv>
  );
};

export default function Chat({
  isEmbedded = false,
  handleSaveNewFlow,
  language = "en",
  handleBackToFlows,
  loadFlows
}) {
  const t = translations[language];
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [step, setStep] = useState(0);
  const [showUploadOptions, setShowUploadOptions] = useState(false);
  const [showDocument, setShowDocument] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [messages, setMessages] = useState([]);
  const [columns, setColumns] = useState(["Documento"]);
  const [extractedData, setExtractedData] = useState({
    Documento: []
  });
  const fileInputRef = useRef(null);
  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const chatContainerRef = useRef(null);
  const dragCounter = useRef(0);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [flowDocuments, setFlowDocuments] = useState([]);
  const [fieldsToExtract, setFieldsToExtract] = useState({});
  const [conditionalFields, setConditionalFields] = useState([]);
  const [calculatedFields, setCalculatedFields] = useState([]);
  const [crossedConditionalFields, setCrossedConditionalFields] = useState([]);
  const [crossedCalculatedFields, setCrossedCalculatedFields] = useState([]);
  const [selectedFieldsToExtract, setSelectedFieldsToExtract] = useState([]);
  const [isFlowExpanded, setIsFlowExpanded] = useState(true);
  const [showFlowDocuments, setShowFlowDocuments] = useState(false);
  const [selectedDocumentToConfigureId, setSelectedDocumentToConfigureId] =
    useState(null);
  const [documentFlowName, setDocumentFlowName] = useState({});
  const [documentCommonName, setDocumentCommonName] = useState({});
  const [documentCommonFormat, setDocumentCommonFormat] = useState({});
  const [documentRecognition, setDocumentRecognition] = useState({});
  const [documentsSource, setDocumentsSource] = useState("");
  const [creatingFlow, setCreatingFlow] = useState(false);
  const [isPending, startTransition] = useTransition();
  const [showFilePreview, setShowFilePreview] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);
  const [emailConnectionSource, setEmailConnectionSource] = useState("");
  const [editingTableId, setEditingTableId] = useState(null);
  const [editingTableName, setEditingTableName] = useState("");
  const [editingFieldId, setEditingFieldId] = useState(null);
  const [editingFieldName, setEditingFieldName] = useState("");
  const [isSelectionMode, setIsSelectionMode] = useState(false);
  const [showDeleteButtons, setShowDeleteButtons] = useState(false);
  const [showFlowDeleteButtons, setShowFlowDeleteButtons] = useState(false);

  useEffect(() => {
    if (chatContainerRef.current) {
      const scrollOptions = {
        behavior: "smooth",
        block: "end"
      };
      chatContainerRef.current.scrollIntoView(scrollOptions);
    }
  }, [messages]);

  useEffect(() => {
    // console.log("Getting crossed conditional fields");
    let crossedConditionalFieldsTemp = [];
    conditionalFields.forEach((field) => {
      if (field.comparedWith.type === "FIELD") {
        if (
          field.flowDocumentToCompareId !== field.comparedWith.flowDocumentId
        ) {
          crossedConditionalFieldsTemp.push(field);
        }
      }
    });
    // console.log("Crossed conditional fields:", crossedConditionalFieldsTemp);
    setCrossedConditionalFields(crossedConditionalFieldsTemp);
  }, [conditionalFields]);

  useEffect(() => {
    // console.log("Getting crossed calculated fields");
    let crossedCalculatedFieldsTemp = [];
    calculatedFields.forEach((field) => {
      if (field.calculatedWith.type === "FIELD") {
        if (
          field.flowDocumentToCalculateId !==
          field.calculatedWith.flowDocumentId
        ) {
          crossedCalculatedFieldsTemp.push(field);
        }
      }
    });
    // console.log("Crossed calculated fields:", crossedCalculatedFieldsTemp);
    // console.log(fieldsToExtract);
    setCrossedCalculatedFields(crossedCalculatedFieldsTemp);
  }, [calculatedFields]);

  const toggleFlowExpansion = () => {
    startTransition(() => {
      setIsFlowExpanded(!isFlowExpanded);
    });
  };

  const createNewFlow = async () => {
    if (!creatingFlow) {
      try {
        setCreatingFlow(true);
        const response = await handleSaveNewFlow({
          documentFlowName: documentFlowName,
          description: "",
          mantisBusinessId: "672e82bf3ab5c40012235316",
          mantisBusinessUserId: "123456",
          fieldsToExtract: fieldsToExtract,
          flowDocuments: flowDocuments,
          conditionalFields: conditionalFields,
          documentCommonName: documentCommonName,
          documentCommonFormat: documentCommonFormat,
          calculatedFields: calculatedFields,
          sources:
            documentsSource === "EMAIL"
              ? [emailConnectionSource]
              : [documentsSource]
        });
        if (response.success) {
          alert("Flujo creado correctamente");
          if (
            documentsSource === "EMAIL" &&
            emailConnectionSource === "GMAIL"
          ) {
            window.open(
              `${backUrl}/googleOAuth/auth?mantisBusinessId=672e82bf3ab5c40012235316&flowId=${response.flowId}`
            );
          }
          handleBackToFlows();
          loadFlows();
        } else {
          alert("Error al crear el flujo");
        }
        setCreatingFlow(false);
      } catch (error) {
        alert("Error al crear el flujo");
        setCreatingFlow(false);
      }
    } else {
      return;
    }
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleSendMessage = async () => {
    if (!message.trim()) return;

    setMessages((prev) => [...prev, {type: "user", content: message}]);
    setIsLoading(true);

    try {
      if (selectedFile) {
        let existingFieldsString = "";
        if (fieldsToExtract[selectedFile.id].length > 0) {
          fieldsToExtract[selectedFile.id].forEach((field) => {
            existingFieldsString += `{ "name": "${field.name}", "nameInDocument": "${field.nameInDocument}", "type": "${field.type}", "_id": "${field.id}", "documentId":"${field.flowDocumentId}", "currentValue":"${field.value}"
              }" }, `;
          });
          existingFieldsString = existingFieldsString.slice(0, -2);
          existingFieldsString = `[ ${existingFieldsString} ]`;
        }
        //Flow to extract data from the selected file
        let systemInstructions = `You are a specialist extracting data from a document. The document is called ${
          selectedFile.name
        }. In addition to the document, you are provided with a list of existing fields that have already been extracted. Your task is to analyze the user's statement and extract any additional fields not already included in the provided list. Output only the newly extracted fields in the following JSON format. If a field is not found, simply omit it from the JSON object:
        Existing Fields (for reference only, do not include in the output):
        ${existingFieldsString || "No existing fields"}

        Output Format for Newly Extracted Fields:
        {
          "fields": [
            {
              name: (Name of the field),
              nameInDocument: (Name of the field in the document, is generally the same as the name),
              value: (Value of the field),
              type: "string", "number", "date",
            }
          ],
          ...,
          message: "Respond which fields were extracted, which ones were not and any additional information."
         }
          
         The answer must be in the same language as the input.`;
        console.log("message", message, "file", selectedFile);
        const response = await getMantisResponse(
          message,
          [
            {
              file_name: selectedFile.name,
              file_base64: selectedFile.exampleDocument.base64,
              file_size: selectedFile.size,
              file_type: selectedFile.exampleDocument.type
            }
          ],
          systemInstructions
        );
        console.log("Response 1:", response);
        if (response && response.response) {
          //Clean the response as a JSON object
          let cleanResponse = response.response
            .replace("```json", "")
            .replace("```", "");
          let responseJSON = JSON.parse(cleanResponse);
          console.log("RESPONSE JSON 2:", responseJSON);
          //Add the extracted fields to the fieldsToExtract object
          if (responseJSON.fields && responseJSON.fields.length > 0) {
            let fieldsToExtractTemp = {...fieldsToExtract};
            let existingFields = fieldsToExtractTemp[selectedFile.id] || [];
            responseJSON.fields.forEach((field) => {
              if (!existingFields.find((f) => f.name === field.name)) {
                existingFields.push({
                  name: field.name,
                  flowId: selectedFile.flowId,
                  flowDocumentId: selectedFile.id,
                  mantisBusinessId: "65fb64dc",
                  nameInDocument: field.nameInDocument,
                  type: field.type,
                  id: `temp-field-id-${selectedFile.id}-${existingFields.length}`,
                  value: field.value
                });
              }
            });
            fieldsToExtractTemp[selectedFile.id] = existingFields;
            console.log("Fields to extract:", fieldsToExtractTemp);
            setFieldsToExtract(fieldsToExtractTemp);
            setSelectedFieldsToExtract(existingFields);
          }
          setMessages((prev) => [
            ...prev,
            {
              type: "bot",
              content: responseJSON.message
            }
          ]);
        } else {
          throw new Error("Respuesta inválida del servidor");
        }
      } else {
        //Classify the message to determine if it is a conditional statement or a calculation
        let systemInstructionsCalulation = `System Instruction Prompt:
                                                
                                          You will be provided with user requests. Determine if the request describes a conditional operation or a calculation. Return only the string "CONDITIONAL" or "CALCULATION", without any additional explanation or punctuation.
                                                
                                          Examples of Conditional requests:
                                                
                                              If the temperature is above 90 degrees, display a "Heat Warning." Otherwise, display "Temperature is OK."
                                              Check if the user's input is a valid email address. If it is, proceed. If not, display an error message.
                                              Given a list of names, return only those names that start with the letter "A".
                                              If the account balance is below zero, send a low balance alert.
                                                
                                          Examples of Calculation requests:
                                                
                                              Calculate the average of the following numbers: 10, 20, 30, and 40.
                                              Subtract the cost of goods sold from the revenue to determine the gross profit.
                                              Multiply the length and width to find the area of the rectangle.
                                              Divide the total distance by the total time to calculate the average speed.
                                              Compute the product of the elements in the given array.
                                              Determine the difference between the highest and lowest values in the dataset.
                                              What is 15 multiplied by 4?
                                              Add 5, 10, and 15 together.
                                              Find the quotient of 100 divided by 4.
                                              What is 25 minus 10?
                                          `;
        let action = "CONDITONAL";
        const calcOrCondResponse = await getMantisResponse(
          message,
          [],
          systemInstructionsCalulation
        );
        console.log("Response:", calcOrCondResponse);
        if (calcOrCondResponse && calcOrCondResponse.response) {
          if (
            calcOrCondResponse.response.toLowerCase().includes("conditional")
          ) {
            action = "CONDITONAL";
          }
          if (
            calcOrCondResponse.response.toLowerCase().includes("calculation")
          ) {
            action = "CALCULATION";
          }
        }
        let fieldsString = "";
        //Make fieldsString match the format [ { "name": "Name", "nameInDocument": "Patient name", "type": "string", "_id": "1234" }, { "name": "Identification", "nameInDocument": "Identificacion del paciente", "type": "string", "_id": "5678" }, { "name": "Request Date", "nameInDocument": "Fecha de solicitud de la cita", "type": "date", "_id": "9101112" }, { "name": "Age", "nameInDocument": "Edad del paciente", "type": "number", "_id": "13141516" } , { "name": "Birth Date", "nameInDocument": "Fecha de nacimiento  del paciente", "type": "date", "_id": "17181920" }]
        conditionalFields.forEach((field) => {
          fieldsString += `{ "name": "${field.name}", "nameInDocument": "${
            field.name
          }", "type": "${field.type}", "_id": "${field.id}", "documentId":"${
            field.flowDocumentToCompareId
          }", "currentValue":"${field.exampleValue}", "documentName":"${
            flowDocuments.find((d) => {
              return d.id === field.flowDocumentToCompareId;
            }).name || ""
          }" }, `;
        });
        calculatedFields.forEach((field) => {
          fieldsString += `{ "name": "${field.name}", "nameInDocument": "${
            field.name
          }", "type": "${field.type}", "_id": "${field.id}", "documentId":"${
            field.flowDocumentToCalculateId
          }", "currentValue":"${field.exampleValue}", "documentName":"${
            flowDocuments.find((d) => {
              return d.id === field.flowDocumentToCalculateId;
            }).name || ""
          }" }, `;
        });
        Object.entries(fieldsToExtract).forEach(([docId, fields]) => {
          fields.forEach((field) => {
            fieldsString += `{ "name": "${field.name}", "nameInDocument": "${
              field.nameInDocument
            }", "type": "${field.type}", "_id": "${field.id}", "documentId":"${
              field.flowDocumentId
            }", "currentValue":"${field.value}", "documentName":"${
              flowDocuments.find((d) => {
                return d.id === field.flowDocumentId;
              }).name || ""
            }" }, `;
          });
        });
        fieldsString = fieldsString.slice(0, -2);
        fieldsString = `[ ${fieldsString} ]`;
        if (action === "CALCULATION") {
          console.log("FIELDSSTRING:", fieldsString);
          //Flow to create conditional fields
          if (Object.keys(fieldsToExtract).length > 0) {
            let prompt = `Calculation: ${message}
  Possible fields: ${fieldsString}`;
            let systemInstructions = `You are an expert at creating JSON-based calculation logic from natural language instructions. You will be given a calculation statement in natural language, along with a list of possible fields. Your task is to interpret the statement and return a JSON object with specific attributes to define the calculation logic.

      ### JSON Response Structure
      The JSON object you need to create should follow this structure:

      json
      {
        "flowFieldToCalculateId": "ID of the field being evaluated",
        "flowDocumentToCalculateId": "ID of the document of the field being evaluated",
        "calculatedWith": {
          "type": "VALUE or FIELD",
          "value": "Value if type is VALUE",
          "fieldId": "Field ID if type is FIELD",
          "flowDocumentId": "Document ID if type is FIELD"
        },
        "operator": "SUM, SUBTRACT, MULTIPLY, DIVIDE",
        "name": "Name of the new calculated field",
        "exampleValue": "Value of the field after applying the calculation given the current values of the fields",
        "type": "Type of the field (string, number, or date)"
      }


      ### Possible Fields Structure
      You will also receive a set of fields to reference, formatted like this:

      json
      [
        {
          "name": "Field name",
          "nameInDocument": "Field name as it appears in the document",
          "type": "string, number, or date",
          "_id": "Unique identifier for the field",
          "documentId": "ID of the document the field belongs to"
          "currentValue": "Current value of the field".
	  "documentName":"Name of the document containing the field"
        },
        ...
      ]


      ### Task
      1. Analyze the natural language calculation.
      2. Identify the relevant field from the list provided.
      3. Create the JSON structure using the appropriate field IDs and values.

      ### Example

      *Input:*
      - Calculation: "I want to create a column called Efectivo Total which sums the Total Efectivo coming from the Cuadre Oficina JA and the Total Efectivo coming from Cuadre Oficina JN
      - Possible fields:
        json
        [
          { "name": "Fecha", "nameInDocument": "Fecha Efectiva", "type": "date", "_id": "1234", "documentId": "temp-file-id-0", "currentValue": "01/01/2024", "documentName": "Reporte Cierre" },
          { "name": "Total Efectivo", "nameInDocument": "Total Efectivo", "type": "number", "_id": "5678", "documentId": "temp-file-id-1", "currentValue": "305213", "documentName": "Cuadre Oficina JN" },
          { "name": "Oficina", "nameInDocument": "Nombre de la Oficina", "type": "string", "_id": "9101112", "documentId": "temp-file-id-0", "currentValue": "Valvanera", "documentName": "Reporte Cierre" },
          { "name": "Total Efectivo", "nameInDocument": "Total Efectivo", "type": "number", "_id": "13141516", "documentId": "temp-file-id-0", "currentValue": 4582452, "documentName": "Cuadre Oficina JA" },
        ]


      *Expected Output:*
      json
      {
    "flowFieldToCalculateId": "13141516",
    "flowDocumentToCalculateId": ""temp-file-id-0",
    "calculatedWith": {
     "type": "FIELD",
     "fieldId": "5678",
     "flowDocumentId": ""temp-file-id-1"
    },
    "operator": "SUM",
    "name": "Efectivo Total",
    "exampleValue": 4887665,
    "type": "number"
     }

      Use this structure to parse natural language calculations and return the corresponding JSON object. If you cant build the calculation, please respond with "No calculation found".`;

            const response = await getMantisResponse(
              prompt,
              [],
              systemInstructions
            );
            console.log("Response FROM PERLA :b", response.response);
            if (response && response.response) {
              if (
                response.response.toLowerCase().includes("no calculation found")
              ) {
                setMessages((prev) => [
                  ...prev,
                  {
                    type: "bot",
                    content: "No se encontr un campo calculado en el mensaje."
                  }
                ]);
                return;
              }
              //Clean the response as a JSON object
              let cleanResponse = response.response
                .replace("```json", "")
                .replace("```", "");
              let responseJSON = JSON.parse(cleanResponse);
              if (Object.keys(responseJSON).length > 0) {
                const {
                  flowFieldToCalculateId,
                  calculatedWith,
                  operator,
                  name,
                  type
                } = responseJSON;
                console.log("OPERATOR:", operator);

                // Encuentra los campos necesarios para el cálculo
                const fieldToCalculate = Object.values(fieldsToExtract)
                  .flat()
                  .find((field) => field.id === flowFieldToCalculateId);
                const fieldToUse = Object.values(fieldsToExtract)
                  .flat()
                  .find((field) => field.id === calculatedWith.fieldId);

                if (fieldToCalculate && fieldToUse) {
                  const value1 = cleanNumber(fieldToCalculate.value);
                  const value2 = cleanNumber(fieldToUse.value);
                  console.log("VALUE 1:", value1);
                  console.log("VALUE 2:", value2);
                  let result;

                  // Realiza el cálculo basado en el operador
                  switch (operator) {
                    case "SUM":
                      result = value1 + value2;
                      break;
                    case "SUBTRACT":
                      result = value1 - value2;
                      break;
                    case "MULTIPLY":
                      result = value1 * value2;
                      break;
                    case "DIVIDE":
                      result = value2 !== 0 ? value1 / value2 : 0;
                      break;
                    default:
                      console.error("Operador no soportado:", operator);
                      return;
                  }
                  console.log("RESULT :O", result);
                  const formattedResult = new Intl.NumberFormat("es-CO", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }).format(result);
                  console.log("FORMATTED RESULT:", formattedResult);

                  let calculatedFieldsTemp = [...calculatedFields];
                  calculatedFieldsTemp.push({
                    flowId: "temp-flow-id",
                    flowFieldToCalculateId,
                    calculatedWith,
                    operator,
                    name,
                    exampleValue: formattedResult,
                    flowDocumentToCalculateId:
                      responseJSON.flowDocumentToCalculateId,
                    type,
                    id: `temp-calculated-field-id-${calculatedFieldsTemp.length}`
                  });

                  setCalculatedFields(calculatedFieldsTemp);
                  setMessages((prev) => [
                    ...prev,
                    {
                      type: "bot",
                      content: `Campo calculado procesado correctamente. Resultado: ${formattedResult}`
                    }
                  ]);
                } else {
                  console.error("JSON de respuesta vacío o inválido.");
                }
              }
              //Add the extracted fields to the fieldsToExtract object
              // if (Object.keys(responseJSON).length > 0) {
              //   let calculatedFieldsTemp = [...calculatedFields];
              //   calculatedFieldsTemp.push({
              //     flowId: "temp-flow-id",
              //     flowFieldToCalculateId: responseJSON.flowFieldToCalculateId,
              //     calculatedWith: responseJSON.calculatedWith,
              //     operator: responseJSON.operator,
              //     name: responseJSON.name,
              //     exampleValue: responseJSON.exampleValue,
              //     flowDocumentToCalculateId:
              //       responseJSON.flowDocumentToCalculateId,
              //     type: responseJSON.type,
              //     id: `temp-calculated-field-id-${calculatedFieldsTemp.length}`,
              //   });
              //   setCalculatedFields(calculatedFieldsTemp);
              // }
            }
          }
        } else if (action === "CONDITONAL") {
          //Flow to create conditional fields
          if (Object.keys(fieldsToExtract).length > 0) {
            let prompt = `Conditional: ${message}
  Possible fields: ${fieldsString}`;
            console.log("Prompt:", prompt);
            let systemInstructions = `You are an expert at creating JSON-based conditional logic from natural language instructions. You will be given a conditional statement in natural language, along with a list of possible fields. Your task is to interpret the statement and return a JSON object with specific attributes to define the conditional logic.

      ### JSON Response Structure
      The JSON object you need to create should follow this structure:

      json
      {
        "flowFieldToCompareId": "ID of the field being evaluated",
        "flowDocumentToCompareId": "ID of the document of the field being evaluated",
        "comparedWith": {
          "type": "VALUE or FIELD",
          "value": "Value if type is VALUE",
          "fieldId": "Field ID if type is FIELD",
          "flowDocumentId": "Document ID if type is FIELD"
        },
        "operator": "EQUAL_TO, DIFFERENT_THAN, GREATER_THAN_OR_EQUAL_TO, LESS_THAN_OR_EQUAL_TO, GREATER_THAN, or LESS_THAN",
        "name": "Name of the new conditional field",
        "valueIfTrue": "Value if the condition is true",
        "valueIfFalse": "Value if the condition is false",
        "exampleValue": "Value of the field after applying the conditional given the current values of the fields",
        "type": "Type of the field (string, number, or date)"
      }


      ### Possible Fields Structure
      You will also receive a set of fields to reference, formatted like this:

      json
      [
        {
          "name": "Field name",
          "nameInDocument": "Field name as it appears in the document",
          "type": "string, number, or date",
          "_id": "Unique identifier for the field",
          "documentId": "ID of the document the field belongs to"
          "currentValue": "Current value of the field"
        },
        ...
      ]


      ### Task
      1. Analyze the natural language condition.
      2. Identify the relevant field from the list provided.
      3. Create the JSON structure using the appropriate field IDs and values.

      ### Example

      *Input:*
      - Conditional: "If the age of the person is greater than or equal to 18, classify them as 'Able.' Otherwise, classify them as 'Not Able.'"
      - Possible fields:
        json
        [
          { "name": "Name", "nameInDocument": "Patient name", "type": "string", "_id": "1234", "documentId": "temp-file-id-0", "currentValue": "John Doe" },
          { "name": "Identification", "nameInDocument": "Identificacion del paciente", "type": "string", "_id": "5678", "documentId": "temp-file-id-0", "currentValue": "123456789" },
          { "name": "Request Date", "nameInDocument": "Fecha de solicitud de la cita", "type": "date", "_id": "9101112", "documentId": "temp-file-id-0", "currentValue": "2022-01-01" },
          { "name": "Age", "nameInDocument": "Edad del paciente", "type": "number", "_id": "13141516", "documentId": "temp-file-id-0", "currentValue": 18 },
        ]


      *Expected Output:*
      json
      {
        "flowFieldToCompareId": "13141516",
        "flowDocumentToCompareId": "temp-file-id-0",
        "comparedWith": {
          "type": "VALUE",
          "value": 18
        },
        "operator": "GREATER_THAN_OR_EQUAL_TO",
        "name": "Able",
        "valueIfTrue": "Able",
        "valueIfFalse": "Not Able",
        "exampleValue": "Able",
        "type": "string"
      }


      Use this structure to parse natural language conditions and return the corresponding JSON object. If you cant build the conditional, please respond with "No conditional found".`;

            const response = await getMantisResponse(
              prompt,
              [],
              systemInstructions
            );
            console.log("Response:", response);
            if (response && response.response) {
              if (
                response.response.toLowerCase().includes("no conditional found")
              ) {
                setMessages((prev) => [
                  ...prev,
                  {
                    type: "bot",
                    content: "No se encontró una condición en el mensaje."
                  }
                ]);
                return;
              }
              //Clean the response as a JSON object
              let cleanResponse = response.response
                .replace("```json", "")
                .replace("```", "");
              let responseJSON = JSON.parse(cleanResponse);
              console.log("Response JSON:", responseJSON);
              //Add the extracted fields to the fieldsToExtract object
              if (Object.keys(responseJSON).length > 0) {
                let conditionalFieldsTemp = [...conditionalFields];
                conditionalFieldsTemp.push({
                  flowId: "temp-flow-id",
                  flowFieldToCompareId: responseJSON.flowFieldToCompareId,
                  comparedWith: responseJSON.comparedWith,
                  operator: responseJSON.operator,
                  name: responseJSON.name,
                  valueIfTrue: responseJSON.valueIfTrue,
                  valueIfFalse: responseJSON.valueIfFalse,
                  flowDocumentToCompareId: responseJSON.flowDocumentToCompareId,
                  exampleValue: responseJSON.exampleValue,
                  type: responseJSON.type,
                  id: `temp-conditional-field-id-${conditionalFieldsTemp.length}`
                });
                setConditionalFields(conditionalFieldsTemp);
                console.log("Conditional Fields:", conditionalFieldsTemp);
              }
              setMessages((prev) => [
                ...prev,
                {
                  type: "bot",
                  content: "Conditional processed correctly."
                }
              ]);
            } else {
              throw new Error("Respuesta inválida del servidor");
            }
          }
        }
      }
    } catch (error) {
      let errorMessage = t.errors.processingError;

      if (error instanceof Error) {
        if (error.message.includes("Network Error")) {
          errorMessage = t.errors.networkError;
        }
        console.error("Error detallado:", error.message);
      }

      setMessages((prev) => [
        ...prev,
        {
          type: "bot",
          content: errorMessage
        }
      ]);
    } finally {
      setIsLoading(false);
      setMessage("");
    }
  };

  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setIsDragging(true);
    }
  };

  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current--;
    if (dragCounter.current === 0) {
      setIsDragging(false);
    }
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    dragCounter.current = 0;

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const files = Array.from(e.dataTransfer.files).filter(
        (file) =>
          file.type.includes("image/") || file.type.includes("application/pdf")
      );
      if (files.length > 0) {
        handleFilesUpload(files);
      } else {
        setMessages((prev) => [
          ...prev,
          {
            type: "bot",
            content: "Por favor, sube solo archivos de imagen o PDF."
          }
        ]);
      }
    }
  };

  const handlePreviewClick = (doc) => {
    setPreviewFile(doc);
    setShowFilePreview(true);
  };

  const handleFilesUpload = async (files) => {
    setIsLoading(true);
    setIsUploaded(false);
    setUploadedFiles([]);
    let newDocuments = [];
    let fieldsToExtractTemp = {};
    try {
      let index = 0;
      for (const file of files) {
        const base64 = await fileToBase64(file);
        const fileData = {
          file_name: file.name,
          file_size: file.size,
          file_base64: base64,
          file_type: file.type
        };
        const response = await uploadDocument(fileData);
        console.log("Response:", response);
        setUploadedFiles((prev) => [...prev, file]);
        newDocuments.push({
          name: file.name,
          description: "",
          mantisBusinessId: "672e82bf3ab5c40012235316",
          identificationMethods: [],
          generalPrompt: "",
          flowId: "temp-flow-id",
          exampleDocument: {
            base64: base64,
            name: file.name,
            remoteFile: "",
            type: file.type
          },
          isNew: true,
          id: `temp-file-id-${index}`,
          size: file.size
        });
        fieldsToExtractTemp[`temp-file-id-${index}`] = [];

        setExtractedData((prev) => ({
          ...prev,
          Documento: [...(prev.Documento || []), file.name]
        }));

        setMessages((prev) => [
          ...prev,
          {type: "user", content: `Document loaded: ${file.name}`},
          {
            type: "bot",
            content: "Document analyzed correctly."
          }
        ]);
        index++;
      }
      setFieldsToExtract(fieldsToExtractTemp);
      setSelectedFieldsToExtract(fieldsToExtract["temp-file-id-0"]);
      setFlowDocuments(newDocuments);
      setSelectedFile({
        name: files[0].name,
        description: "",
        mantisBusinessId: "672e82bf3ab5c40012235316",
        identificationMethods: [],
        generalPrompt: "",
        flowId: "temp-flow-id",
        exampleDocument: {
          base64: await fileToBase64(files[0]),
          name: files[0].name,
          remoteFile: "",
          type: files[0].type
        },
        isNew: true,
        id: "temp-file-id-0",
        size: files[0].size
      });
      setIsUploaded(true);
      setStep(1);
      setShowPreview(true);
    } catch (error) {
      console.error("Error al subir los archivos:", error);
      setMessages((prev) => [
        ...prev,
        {
          type: "bot",
          content:
            "Hubo un error al subir los archivos. Por favor, intenta nuevamente."
        }
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  const saveFlow = () => {
    setShowFlowDocuments(true);
  };

  const handleDocumentClick = (docId) => {
    setSelectedDocumentToConfigureId(docId);
  };

  const handleRecognitionChange = (docId, value) => {
    setDocumentRecognition((prev) => ({...prev, [docId]: value}));
  };

  const handleSourceChange = (value) => {
    setDocumentsSource(value);
  };

  const handleDocumentFlowNameChange = (docId, value) => {
    setDocumentFlowName((prev) => ({...prev, [docId]: value}));
  };

  const handleDocumentCommonNameChange = (docId, value) => {
    setDocumentCommonName((prev) => ({...prev, [docId]: value}));
  };

  const handleDocumentCommonFormatChange = (docId, value) => {
    let formats = documentCommonFormat[docId] || [];
    if (formats.includes(value)) {
      formats = formats.filter((format) => format !== value);
    } else {
      formats.push(value);
    }
    setDocumentCommonFormat((prev) => ({...prev, [docId]: formats}));
  };

  const handleDeleteTable = (tableId) => {
    if (window.confirm(t.flowCreation.deleteTableConfirm)) {
      const updatedFields = {...fieldsToExtract};
      Object.keys(updatedFields).forEach((docId) => {
        updatedFields[docId] = updatedFields[docId].filter(
          (field) => field.id !== tableId
        );
      });
      setFieldsToExtract(updatedFields);
      setSelectedFieldsToExtract(updatedFields[selectedFile?.id] || []);
    }
  };

  const handleEditTable = (tableId, currentName) => {
    setEditingTableId(tableId);
    setEditingTableName(currentName);
  };

  const handleSaveTableEdit = (tableId) => {
    const updatedFields = {...fieldsToExtract};
    Object.keys(updatedFields).forEach((docId) => {
      updatedFields[docId] = updatedFields[docId].map((field) => {
        if (field.id === tableId) {
          return {...field, name: editingTableName};
        }
        return field;
      });
    });
    setFieldsToExtract(updatedFields);
    setSelectedFieldsToExtract(updatedFields[selectedFile?.id] || []);
    setEditingTableId(null);
    setEditingTableName("");
  };

  const handleDeleteField = (docId, fieldId) => {
    if (window.confirm(t.flowCreation.deleteTableConfirm)) {
      const updatedFields = {...fieldsToExtract};
      updatedFields[docId] = updatedFields[docId].filter(
        (field) => field.id !== fieldId
      );
      setFieldsToExtract(updatedFields);
      setSelectedFieldsToExtract(updatedFields[docId] || []);
    }
  };

  const handleEditField = (field) => {
    setEditingFieldId(field.id);
    setEditingFieldName(field.name);
  };

  const handleSaveFieldEdit = (docId, fieldId) => {
    const updatedFields = {...fieldsToExtract};
    updatedFields[docId] = updatedFields[docId].map((field) => {
      if (field.id === fieldId) {
        return {...field, name: editingFieldName};
      }
      return field;
    });
    setFieldsToExtract(updatedFields);
    setSelectedFieldsToExtract(updatedFields[docId] || []);
    setEditingFieldId(null);
    setEditingFieldName("");
  };

  const uploadOptions = [
    {
      name: "Gmail",
      icon: <Mail className="w-5 h-5" />,
      action: () => setShowDocument(true)
    },
    {name: "Onedrive", icon: <Cloud className="w-5 h-5" />},
    {name: "Drive", icon: <Cloud className="w-5 h-5" />},
    {name: "WhatsApp", icon: <MessageSquare className="w-5 h-5" />},
    {name: "API", icon: <Database className="w-5 h-5" />},
    {name: "SAP", icon: <BarChart4 className="w-5 h-5" />},
    {name: "Outlook", icon: <Mail className="w-5 h-5" />}
  ];

  const handleAddFiles = (event) => {
    const newFiles = Array.from(event.target.files);
    let filesCopy = [...uploadedFiles];

    newFiles.forEach((newFile) => {
      const isDuplicate = filesCopy.some(
        (existingFile) =>
          existingFile.name === newFile.name &&
          existingFile.size === newFile.size
      );

      if (!isDuplicate) {
        filesCopy.push(newFile);
      }
    });

    setUploadedFiles(filesCopy);
    handleFilesUpload(filesCopy);
  };

  const handleRemoveFile = (indexToRemove) => {
    let removedFiles = uploadedFiles.filter(
      (_, index) => index !== indexToRemove
    );
    setUploadedFiles(removedFiles);
    handleFilesUpload(removedFiles);
  };

  const handleDeleteFlowDocument = (id) => {
    let flowDocumentsCopy = flowDocuments;
    flowDocumentsCopy = flowDocuments.filter((doc) => doc.id !== id);
    setFlowDocuments(flowDocumentsCopy);
  };

  const PreviewContent = ({file}) => {
    const [previewUrl, setPreviewUrl] = useState(null);

    useEffect(() => {
      const loadPreviewUrl = async () => {
        try {
          if (file.exampleDocument?.base64) {
            const base64Response = await fetch(file.exampleDocument.base64);
            const blob = await base64Response.blob();
            setPreviewUrl(URL.createObjectURL(blob));
          } else {
            setPreviewUrl(URL.createObjectURL(file));
          }
        } catch (error) {
          console.error("Error loading preview:", error);
        }
      };

      loadPreviewUrl();
      return () => {
        if (previewUrl) {
          URL.revokeObjectURL(previewUrl);
        }
      };
    }, [file]);

    const getFileType = () => {
      if (file.exampleDocument?.type) {
        return file.exampleDocument.type;
      }
      return file.type || "";
    };

    const fileType = getFileType();

    if (!previewUrl) {
      return (
        <div className="flex items-center justify-center h-[500px]">
          <Loader2 className="w-8 h-8 animate-spin" />
        </div>
      );
    }

    return (
      <motion.div
        initial={{opacity: 0, scale: 0.9}}
        animate={{opacity: 1, scale: 1}}
        transition={{duration: 0.3}}
        className="flex flex-col items-center w-full"
      >
        {fileType && fileType.toLowerCase().includes("pdf") ? (
          <div className="w-full h-[70vh] bg-white rounded-lg shadow-lg p-2">
            <iframe
              src={previewUrl}
              className="w-full h-full"
              title="PDF Preview"
            />
          </div>
        ) : fileType && fileType.toLowerCase().includes("image") ? (
          <div className="bg-white rounded-lg shadow-lg p-4">
            <img
              src={previewUrl}
              alt="Document Preview"
              className="w-full h-auto object-contain"
              style={{maxHeight: "70vh"}}
            />
          </div>
        ) : (
          <div className="flex items-center justify-center h-[500px] bg-muted/5 rounded-lg">
            <p className="text-muted-foreground">
              {t.filePreview.notAvailable}
            </p>
          </div>
        )}
      </motion.div>
    );
  };

  return (
    <Card
      className={`h-full rounded-none border-0 ${
        isEmbedded
          ? "bg-background/60 backdrop-blur-sm border-accent/20"
          : "bg-gradient-to-br from-background to-accent/5"
      }`}
    >
      {!uploadedFiles.length ? (
        <motion.div
          initial={{opacity: 0}}
          animate={{opacity: 1}}
          exit={{opacity: 0}}
          className={`h-full flex flex-col items-center justify-center p-6 ${
            isEmbedded ? "bg-background/40 backdrop-blur-sm" : ""
          }`}
          onDragEnter={handleDragIn}
          onDragLeave={handleDragOut}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        >
          <div className="text-center">
            <motion.h1
              className="text-4xl font-bold mb-4"
              initial={{y: -20, opacity: 0}}
              animate={{y: 0, opacity: 1}}
              transition={{delay: 0.2}}
            >
              {t.welcome.title}
            </motion.h1>
            <motion.p
              className="mb-8 text-lg text-foreground"
              initial={{y: -20, opacity: 0}}
              animate={{y: 0, opacity: 1}}
              transition={{delay: 0.3}}
            >
              {t.welcome.description}
            </motion.p>
            <motion.div
              initial={{y: 20, opacity: 0}}
              animate={{y: 0, opacity: 1}}
              transition={{delay: 0.4}}
            >
              <Button
                onClick={() => fileInputRef.current?.click()}
                className="transition-all duration-200 hover:bg-primary/90 text-lg px-6 py-3"
                whileHover={{scale: 1.05}}
                whileTap={{scale: 0.95}}
              >
                <Upload className="w-5 h-5 mr-2" />
                {t.welcome.uploadButton}
              </Button>
            </motion.div>
          </div>
        </motion.div>
      ) : (
        <div className="flex h-full">
          {/* Chat section (40% width) */}
          <div className="w-2/5 flex flex-col border-r border-accent/10">
            {/* Chat messages */}
            <ScrollArea className="flex-grow p-4 pt-6">
              <div className="space-y-4" ref={chatContainerRef}>
                <AnimatePresence>
                  {messages.map((msg, index) => (
                    <motion.div
                      key={index}
                      initial={{opacity: 0, y: 20}}
                      animate={{opacity: 1, y: 0}}
                      exit={{opacity: 0, y: -20}}
                      transition={{duration: 0.3}}
                      className={`flex ${
                        msg.type === "user" ? "justify-end" : "justify-start"
                      }`}
                    >
                      <div
                        className={`rounded-lg px-4 py-2 max-w-[80%] ${
                          msg.type === "user"
                            ? "bg-primary text-primary-foreground"
                            : "bg-accent/20 text-accent-foreground"
                        } text-sm`}
                      >
                        {msg.content}
                      </div>
                    </motion.div>
                  ))}
                </AnimatePresence>
                {isLoading && (
                  <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    exit={{opacity: 0, y: -20}}
                    className="flex justify-start"
                  >
                    <div className="rounded-lg px-4 py-2 bg-accent/20 text-accent-foreground text-sm flex items-center">
                      <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                      {t.chat.analyzing}
                    </div>
                  </motion.div>
                )}
              </div>
            </ScrollArea>

            {/* Chat input */}
            <div className="flex-none border-t border-accent/10 p-4 bg-background/50 backdrop-blur-sm">
              <div className="flex gap-2">
                <Input
                  type="text"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyDown={(e) =>
                    e.key === "Enter" && !isLoading && handleSendMessage()
                  }
                  placeholder={
                    selectedFile
                      ? t.chat.inputPlaceholder.withFile
                      : t.chat.inputPlaceholder.withoutFile
                  }
                  disabled={isLoading}
                  className="flex-1"
                />
                <motion.div>
                  <Button onClick={handleSendMessage} disabled={isLoading}>
                    <Send className="w-4 h-4 mr-2" />
                    {t.chat.sendButton}
                  </Button>
                </motion.div>
              </div>
            </div>
          </div>

          {/* Subtle separator */}
          <Separator orientation="vertical" className="h-full opacity-30" />

          {/* Flow and extracted data section (60% width) */}
          <div
            className="w-3/5 flex flex-col relative "
            document
            flow
            onClick={() => {
              setSelectedFile(null);
              setSelectedFieldsToExtract([]);
            }}
          >
            {/* Top section with Save Flow button */}
            <div className="p-4 border-b border-accent/10 flex justify-between items-center bg-background/50 backdrop-blur-sm">
              <h2 className="text-2xl font-semibold">Document Flow</h2>
              <motion.div className="flex flex-row gap-2">
                <input
                  type="file"
                  ref={fileInputRef1}
                  className="hidden"
                  accept="image/*,application/pdf"
                  multiple
                  onChange={handleAddFiles}
                />
                <Button
                  onClick={() => {
                    fileInputRef1.current?.click();
                  }}
                  className="flex items-center justify-center p-2 bg-primary text-white rounded-full shadow-md hover:bg-primary-dark"
                  style={{width: "40px", height: "40px"}}
                >
                  <Plus className="w-6 h-6" />
                </Button>
                <Button
                  onClick={() =>
                    setShowFlowDeleteButtons(!showFlowDeleteButtons)
                  }
                  className={`flex items-center justify-center p-2 text-white rounded-full shadow-md hover:bg-red-70 transition-opacity ${
                    showFlowDeleteButtons
                      ? "bg-red-500 opacity-50"
                      : "bg-red-500 opacity-100"
                  }`}
                  style={{width: "40px", height: "40px"}}
                >
                  <Minus className="w-6 h-6" />
                </Button>
                <Button
                  onClick={saveFlow}
                  variant="outline"
                  size="sm"
                  className="ml-auto"
                >
                  <Save className="w-4 h-4 mr-2" />
                  Save Flow
                </Button>
              </motion.div>
            </div>

            {/* Flow section (remaining height) */}
            <div className="relative flex-1 overflow-hidden bg-grid">
              <style jsx>{`
                .bg-grid {
                  background-size: 40px 40px;
                  background-image: linear-gradient(
                      to right,
                      rgba(176, 176, 176, 0.1) 1px,
                      transparent 1px
                    ),
                    linear-gradient(
                      to bottom,
                      rgba(176, 176, 176, 0.1) 1px,
                      transparent 1px
                    );
                  background-position: top center;
                }
              `}</style>
              <ScrollArea className="h-full">
                <div className="p-6">
                  <div>
                    {flowDocuments.map((doc, index) => (
                      <div
                        key={index}
                        className="flex items-start mb-7 relative"
                      >
                        {showFlowDeleteButtons && (
                          <div
                            onClick={() => {
                              handleDeleteFlowDocument(doc.id);
                            }}
                            className={`absolute -right-300 -top-2 bg-red-500 text-white rounded-full hover:bg-red-700 transition-opacity flex items-center justify-center cursor-pointer opacity-100`}
                            style={{
                              width: "24px",
                              height: "24px",
                              minWidth: "24px",
                              minHeight: "24px",
                              padding: "0",
                              border: "none",
                              boxSizing: "border-box",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              zIndex: 30,
                              pointerEvents: "auto"
                            }}
                          >
                            <Minus className="w-4 h-4" />
                          </div>
                        )}
                        <div
                          id={`doc-${doc.id}`}
                          className={`w-40 h-20 p-2 rounded-lg flex flex-col items-center justify-center cursor-pointer transition-colors border-2 ${
                            selectedFile && selectedFile.id === doc.id
                              ? "border-primary text-primary shadow-lg shadow-primary/50"
                              : "border-primary/30 bg-accent/20 text-accent-foreground hover:bg-accent/30 border-accent/30"
                          }`}
                          style={{
                            boxShadow:
                              selectedFile && selectedFile.id === doc.id
                                ? "0 0 10px rgba(255, 255, 0, 0.8), 0 0 20px rgba(255, 255, 0, 0.6)" // Yellow glow effect
                                : "none" // No glow when not selected
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedFile(doc);
                            setSelectedFieldsToExtract(fieldsToExtract[doc.id]);
                          }}
                        >
                          <FileText className="w-7 h-7 mb-1" />
                          <span className="text-xs h-6 text-center truncate w-full px-1">
                            {doc.name}
                          </span>
                          <Button
                            size="sm"
                            variant="ghost"
                            className="mt-1"
                            onClick={(e) => {
                              e.stopPropagation();
                              console.log("Preview clicked");
                              handlePreviewClick(doc);
                            }}
                          >
                            <Eye className="w-4 h-4" />
                          </Button>
                        </div>

                        {fieldsToExtract[doc.id] &&
                          fieldsToExtract[doc.id].length > 0 && (
                            <>
                              <div
                                id={`fields-${doc.id}`}
                                className="ml-6 rounded-lg border-2 border-primary/30 bg-accent/20 text-accent-foreground"
                              >
                                <table className="w-full border-collapse">
                                  <thead>
                                    <tr>
                                      <th className="border border-gray-300 p-1 text-xs">
                                        Field
                                      </th>
                                      <th className="border border-gray-300 p-1 text-xs">
                                        Value
                                      </th>
                                      <th className="border border-gray-300 p-1 text-xs">
                                        Actions
                                        <div className="flex justify-center gap-1">
                                          <Button
                                            size="icon"
                                            variant="ghost"
                                            className="h-6 w-6"
                                            onClick={() => {
                                              let fieldsToExtractTemp = {
                                                ...fieldsToExtract
                                              };
                                              fieldsToExtractTemp[doc.id] = [];
                                              setFieldsToExtract(
                                                fieldsToExtractTemp
                                              );
                                            }}
                                            title={t.flowCreation.deleteTable}
                                          >
                                            <Trash className="h-3 w-3 text-destructive" />
                                          </Button>
                                        </div>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {fieldsToExtract[doc.id].map(
                                      (field, fieldIndex) => (
                                        <motion.tr
                                          key={fieldIndex}
                                          initial={{opacity: 0}}
                                          animate={{opacity: 1}}
                                          transition={{
                                            duration: 0.3,
                                            delay: 0.4 + fieldIndex * 0.1
                                          }}
                                          className="group"
                                        >
                                          <td className="border border-gray-300 p-1 text-xs break-words">
                                            {editingFieldId === field.id ? (
                                              <div className="flex items-center gap-1">
                                                <Input
                                                  value={editingFieldName}
                                                  onChange={(e) =>
                                                    setEditingFieldName(
                                                      e.target.value
                                                    )
                                                  }
                                                  className="h-6 text-xs"
                                                  placeholder={
                                                    t.flowCreation
                                                      .tableNamePlaceholder
                                                  }
                                                />
                                                <Button
                                                  size="icon"
                                                  variant="ghost"
                                                  className="h-6 w-6"
                                                  onClick={() =>
                                                    handleSaveFieldEdit(
                                                      doc.id,
                                                      field.id
                                                    )
                                                  }
                                                >
                                                  <Save className="h-3 w-3" />
                                                </Button>
                                                <Button
                                                  size="icon"
                                                  variant="ghost"
                                                  className="h-6 w-6"
                                                  onClick={() => {
                                                    setEditingFieldId(null);
                                                    setEditingFieldName("");
                                                  }}
                                                >
                                                  <X className="h-3 w-3" />
                                                </Button>
                                              </div>
                                            ) : (
                                              field.name
                                            )}
                                          </td>
                                          <td className="border border-gray-300 p-1 text-xs break-words">
                                            {field.value}
                                          </td>
                                          <td className="border border-gray-300 p-1 text-xs">
                                            <div className="flex justify-center gap-1">
                                              <Button
                                                size="icon"
                                                variant="ghost"
                                                className="h-6 w-6"
                                                onClick={() =>
                                                  handleEditField(field)
                                                }
                                                title={t.flowCreation.editTable}
                                              >
                                                <Edit className="h-3 w-3" />
                                              </Button>
                                              <Button
                                                size="icon"
                                                variant="ghost"
                                                className="h-6 w-6"
                                                onClick={() =>
                                                  handleDeleteField(
                                                    doc.id,
                                                    field.id
                                                  )
                                                }
                                                title={
                                                  t.flowCreation.deleteField
                                                }
                                              >
                                                <Trash className="h-3 w-3 text-destructive" />
                                              </Button>
                                            </div>
                                          </td>
                                        </motion.tr>
                                      )
                                    )}
                                    {
                                      //Add the conditional fields that are not in the c array
                                      conditionalFields &&
                                        conditionalFields.length > 0 &&
                                        conditionalFields
                                          .filter(
                                            (field) =>
                                              field.flowDocumentToCompareId ===
                                                doc.id &&
                                              !crossedConditionalFields.some(
                                                (crossedField) =>
                                                  crossedField.flowDocumentToCompareId ===
                                                  field.flowDocumentToCompareId
                                              )
                                          )
                                          .map((field, fieldIndex) => (
                                            <tr
                                              key={fieldIndex}
                                              className="bg-accent/30"
                                            >
                                              <td className="border border-gray-300 p-1 text-xs truncate">
                                                {field.name}
                                              </td>
                                              <td className="border border-gray-300 p-1 text-xs truncate">
                                                {field.valueIfTrue}
                                              </td>
                                            </tr>
                                          ))
                                    }
                                    {
                                      //Add the calculated fields that are not in the crossedCalculatedFields array
                                      calculatedFields &&
                                        calculatedFields.length > 0 &&
                                        calculatedFields
                                          .filter(
                                            (field) =>
                                              field.flowDocumentToCalculateId ===
                                                doc.id &&
                                              !crossedCalculatedFields.some(
                                                (crossedField) =>
                                                  crossedField.flowDocumentToCalculateId ===
                                                  field.flowDocumentToCalculateId
                                              )
                                          )
                                          .map((field, fieldIndex) => (
                                            <tr
                                              key={fieldIndex}
                                              className="bg-accent/30"
                                            >
                                              <td className="border border-gray-300 p-1 text-xs truncate">
                                                {field.name}
                                              </td>
                                              <td className="border border-gray-300 p-1 text-xs truncate">
                                                {field.exampleValue}
                                              </td>
                                            </tr>
                                          ))
                                    }
                                  </tbody>
                                </table>
                              </div>
                              <div
                                style={{
                                  position: "relative"
                                }}
                              >
                                <Xarrow
                                  start={`doc-${doc.id}`}
                                  end={`fields-${doc.id}`}
                                  color="#888"
                                  strokeWidth={2}
                                  path="curve"
                                />

                                {(crossedConditionalFields.some(
                                  (field) =>
                                    field.flowDocumentToCompareId === doc.id ||
                                    field.comparedWith.flowDocumentId === doc.id
                                ) ||
                                  crossedCalculatedFields.some(
                                    (field) =>
                                      field.flowDocumentToCalculateId ===
                                        doc.id ||
                                      field.calculatedWith.flowDocumentId ===
                                        doc.id
                                  )) && ( // Check if the current document is involved
                                  <Xarrow
                                    start={`fields-${doc.id}`}
                                    end={`fields-preview`}
                                    color="#888"
                                    strokeWidth={2}
                                    path="curve"
                                  />
                                )}
                              </div>
                            </>
                          )}
                      </div>
                    ))}

                    {/* Extracted Fields Table column */}
                    {crossedConditionalFields.length +
                      crossedCalculatedFields.length >
                      0 && (
                      <div
                        className="relative right-4 top-4 max-w-[610px] "
                        id="fields-preview"
                      >
                        <div
                          id="extracted-fields-table"
                          className={`bg-accent/20 rounded-lg p-3 shadow-lg border-2 border-primary/30 transition-colors hover:bg-accent/30`}
                        >
                          <h3 className="text-lg font-semibold mb-3">
                            Crossed Fields
                          </h3>
                          <table className="w-full border-collapse">
                            <thead>
                              <tr>
                                <th className="border border-gray-300 p-2 text-sm">
                                  Field
                                </th>
                                <th className="border border-gray-300 p-2 text-sm">
                                  Value 1
                                </th>
                                <th className="border border-gray-300 p-2 text-sm">
                                  Condition
                                </th>
                                <th className="border border-gray-300 p-2 text-sm">
                                  Value 2
                                </th>
                                <th className="border border-gray-300 p-2 text-sm">
                                  Example Value
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {crossedConditionalFields.map((field, index) => (
                                <tr key={index} className="bg-gray-200">
                                  <td className="border border-gray-300 p-2 text-sm">
                                    {field.name}
                                  </td>
                                  <td className="border border-gray-300 p-2 text-sm">
                                    {
                                      fieldsToExtract[
                                        field.flowDocumentToCompareId
                                      ]?.find(
                                        (f) =>
                                          f.id === field.flowFieldToCompareId
                                      )?.name
                                    }
                                    {" ("}
                                    {
                                      flowDocuments.find(
                                        (d) =>
                                          d.id === field.flowDocumentToCompareId
                                      ).name
                                    }
                                    {")"}
                                  </td>
                                  <td className="border border-gray-300 p-2 text-sm">
                                    {operatorMappping[field.operator]}
                                  </td>
                                  <td className="border border-gray-300 p-2 text-sm">
                                    {fieldsToExtract[
                                      field.comparedWith.flowDocumentId
                                    ]?.find(
                                      (f) => f.id === field.comparedWith.fieldId
                                    )?.name || "Crossed value"}
                                    {" ("}
                                    {
                                      flowDocuments.find(
                                        (d) =>
                                          d.id ===
                                          field.comparedWith.flowDocumentId
                                      ).name
                                    }
                                    {")"}
                                  </td>

                                  <td className="border border-gray-300 p-2 text-sm">
                                    {field.exampleValue}
                                  </td>
                                </tr>
                              ))}
                              {crossedCalculatedFields.map((field, index) => (
                                <tr key={index} className="bg-gray-200">
                                  <td className="border border-gray-300 p-2 text-sm">
                                    {field.name}
                                  </td>
                                  <td className="border border-gray-300 p-2 text-sm">
                                    {
                                      fieldsToExtract[
                                        field.flowDocumentToCalculateId
                                      ].find(
                                        (f) =>
                                          f.id === field.flowFieldToCalculateId
                                      ).name
                                    }
                                    {" ("}
                                    {
                                      flowDocuments.find(
                                        (d) =>
                                          d.id ===
                                          field.flowDocumentToCalculateId
                                      ).name
                                    }
                                    {")"}
                                  </td>
                                  <td className="border border-gray-300 p-2 text-sm">
                                    {operatorMappping[field.operator]}
                                  </td>
                                  <td className="border border-gray-300 p-2 text-sm">
                                    {fieldsToExtract[
                                      field.calculatedWith.flowDocumentId
                                    ]?.find(
                                      (f) =>
                                        f.id === field.calculatedWith.fieldId
                                    )?.name || "Crossed value"}
                                    {" ("}
                                    {
                                      flowDocuments.find(
                                        (d) =>
                                          d.id ===
                                          field.calculatedWith.flowDocumentId
                                      ).name
                                    }
                                    {")"}
                                  </td>
                                  <td className="border border-gray-300 p-2 text-sm">
                                    {field.exampleValue}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </ScrollArea>
            </div>
          </div>
        </div>
      )}

      <input
        type="file"
        ref={fileInputRef}
        className="hidden"
        accept="image/*,application/pdf"
        multiple
        onChange={(e) => {
          if (e.target.files && e.target.files.length > 0) {
            handleFilesUpload(Array.from(e.target.files));
          }
        }}
      />

      <Dialog open={showPreview} onOpenChange={setShowPreview}>
        <DialogContent className="w-full max-w-7xl mx-auto rounded-lg shadow-2xl bg-background flex flex-col max-h-[90vh]">
          <DialogHeader className="border-b border-border/10 flex-none bg-transparent z-50 ">
            <div className="flex items-center justify-between">
              <DialogTitle className="text-2xl font-bold">
                {t.filePreview.title}
              </DialogTitle>
            </div>
          </DialogHeader>

          <ScrollArea className="flex-1 overflow-scroll">
            <div className="p-6 space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                <AnimatePresence>
                  {uploadedFiles.map((file, index) => (
                    <motion.div
                      key={index}
                      initial={{opacity: 0, scale: 0.9}}
                      animate={{opacity: 1, scale: 1}}
                      exit={{opacity: 0, scale: 0.9}}
                      transition={{duration: 0.3, delay: index * 0.1}}
                      className="group relative bg-background rounded-lg border border-border/10 shadow-sm h-[400px] w-[500px] overflow-y-scroll"
                    >
                      <div className="absolute bottom-0 left-0 right-0 p-4 opacity-0 group-hover:opacity-100 transition-opacity z-20">
                        <p className="text-sm font-medium truncate text-foreground">
                          {file.name}
                        </p>
                        <p className="text-xs text-muted-foreground">
                          {(file.size / 1024 / 1024).toFixed(2)} MB
                        </p>
                      </div>

                      <div className="aspect-[4/5] w-full relative">
                        {file.type.includes("application/pdf") ? (
                          <div className="absolute inset-0 bg-muted/5 flex items-center justify-center">
                            <iframe
                              src={URL.createObjectURL(file)}
                              className="w-full h-full"
                              title={`PDF Preview ${index + 1}`}
                              style={{pointerEvents: "auto"}}
                            />
                          </div>
                        ) : file.type.includes("image/") ? (
                          <img
                            src={URL.createObjectURL(file)}
                            alt={`Document Preview ${index + 1}`}
                            className="w-full h-full object-cover"
                          />
                        ) : (
                          <div className="absolute inset-0 bg-muted/5 flex items-center justify-center">
                            <FileText className="h-12 w-12 text-muted-foreground" />
                          </div>
                        )}
                      </div>

                      <button
                        onClick={() => handleRemoveFile(index)}
                        className={`absolute top-0.5 right-0.5 bg-red-500 text-white rounded-full p-1 hover:bg-red-700 transition-opacity ${
                          showDeleteButtons
                            ? "opacity-100"
                            : "opacity-0 pointer-events-none"
                        }`}
                      >
                        <Minus className="w-4 h-4" />
                      </button>
                    </motion.div>
                  ))}
                </AnimatePresence>
              </div>

              <div className="bg-muted/5 rounded-lg p-6">
                <h3 className="text-lg font-semibold mb-4">Loaded Documents</h3>
                <div className="space-y-2">
                  {uploadedFiles.map((file, index) => (
                    <motion.div
                      key={index}
                      initial={{opacity: 0, y: 20}}
                      animate={{opacity: 1, y: 0}}
                      transition={{duration: 0.3, delay: index * 0.1}}
                      className="flex items-center justify-between py-2 px-4 bg-background rounded-lg"
                    >
                      <div className="flex items-center gap-3">
                        {file.type.includes("application/pdf") ? (
                          <FileText className="h-5 w-5 text-muted-foreground" />
                        ) : (
                          <img
                            src={URL.createObjectURL(file)}
                            alt=""
                            className="h-8 w-8 rounded object-cover"
                          />
                        )}
                        <div>
                          <p className="text-sm font-medium">{file.name}</p>
                          <p className="text-xs text-muted-foreground">
                            {(file.size / 1024 / 1024).toFixed(2)} MB
                          </p>
                        </div>
                      </div>
                    </motion.div>
                  ))}
                </div>
              </div>
            </div>
          </ScrollArea>
          <div className="flex-none border-t border-border/10 bg-background p-6">
            <div className="flex justify-end gap-3">
              <button
                onClick={() => fileInputRef2.current?.click()}
                className="flex items-center justify-center p-2 bg-primary text-white rounded-full shadow-md hover:bg-primary-dark"
                style={{width: "40px", height: "40px"}}
              >
                <Plus className="w-6 h-6" />
              </button>
              <input
                type="file"
                ref={fileInputRef2}
                className="hidden"
                accept="image/*,application/pdf"
                multiple
                onChange={handleAddFiles}
              />
              <button
                onClick={() => setShowDeleteButtons(!showDeleteButtons)}
                className="flex items-center justify-center p-2 bg-red-500 text-white rounded-full shadow-md hover:bg-red-700"
                style={{width: "40px", height: "40px"}}
              >
                <Minus className="w-6 h-6" />
              </button>
              <Button
                variant="destructive"
                onClick={() => {
                  setShowPreview(false);
                  setUploadedFiles([]);
                  setMessages([]);
                }}
                className="min-w-[100px]"
                whileHover={{scale: 1.05}}
                whileTap={{scale: 0.95}}
              >
                Cancel
              </Button>
              <Button
                variant="outline"
                onClick={() => setShowPreview(false)}
                className="min-w-[100px]"
                whileHover={{scale: 1.05}}
                whileTap={{scale: 0.95}}
              >
                Continue
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      {/* Flow Documents Dialog */}
      <Dialog open={showFlowDocuments} onOpenChange={setShowFlowDocuments}>
        <DialogContent className="sm:max-w-[700px] max-h-[80vh] overflow-y-auto">
          <DialogHeader>
            <DialogTitle className="text-2xl font-bold text-primary">
              Document details
            </DialogTitle>
          </DialogHeader>
          <div className="py-6">
            <ul className="space-y-6">
              {flowDocuments.map((doc) => (
                <li
                  key={doc.id}
                  className={`p-6 rounded-lg transition-all duration-300 ease-in-out ${
                    selectedDocumentToConfigureId === doc.id
                      ? "bg-primary/10 shadow-lg"
                      : "bg-background hover:bg-accent/5"
                  }`}
                  onClick={() => handleDocumentClick(doc.id)}
                >
                  <div className="flex items-center justify-between mb-4">
                    <div className="flex items-center space-x-3">
                      <FileText
                        className={`h-6 w-6 ${
                          selectedDocumentToConfigureId === doc.id
                            ? "text-primary"
                            : "text-muted-foreground"
                        }`}
                      />
                      <span
                        className={`text-lg font-medium ${
                          selectedDocumentToConfigureId === doc.id
                            ? "text-primary"
                            : ""
                        }`}
                      >
                        {doc.name}
                      </span>
                    </div>
                    <ChevronRight
                      className={`h-5 w-5 transition-transform duration-300 ${
                        selectedDocumentToConfigureId === doc.id
                          ? "rotate-90"
                          : ""
                      }`}
                    />
                  </div>
                  <div
                    className={`space-y-4 overflow-hidden transition-all duration-300 ease-in-out ${
                      selectedDocumentToConfigureId === doc.id
                        ? "max-h-80 opacity-100"
                        : "max-h-0 opacity-0"
                    }`}
                  >
                    <div className="p-1">
                      <label
                        htmlFor={`flow-name-${doc.id}`}
                        className="block text-sm font-medium text-muted-foreground mb-1"
                      >
                        Name of the document in the flow
                      </label>
                      <Input
                        id={`flow-name-${doc.id}`}
                        placeholder="Ej: Factura principal"
                        value={documentFlowName[doc.id] || ""}
                        onChange={(e) =>
                          handleDocumentFlowNameChange(doc.id, e.target.value)
                        }
                        className="w-full"
                      />
                    </div>
                    <div className="p-1">
                      <label
                        htmlFor={`common-name-${doc.id}`}
                        className="block text-sm font-medium text-muted-foreground mb-1"
                      >
                        How is this document commonly named
                      </label>
                      <Input
                        id={`common-name-${doc.id}`}
                        placeholder="Ej: Invoice_1_2024"
                        value={documentCommonName[doc.id] || ""}
                        onChange={(e) =>
                          handleDocumentCommonNameChange(doc.id, e.target.value)
                        }
                        className="w-full"
                      />
                    </div>
                    <div className="p-1">
                      <label
                        htmlFor={`common-format-${doc.id}`}
                        className="block text-sm font-medium text-muted-foreground mb-1"
                      >
                        In which formats is this document commonly found?
                      </label>
                      <div>
                        <input
                          type="checkbox"
                          id={`common-format-${doc.id}`}
                          className="mr-2"
                          onChange={() => {
                            handleDocumentCommonFormatChange(doc.id, "PDF");
                          }}
                          checked={documentCommonFormat[doc.id]?.includes(
                            "PDF"
                          )}
                        />
                        <label
                          htmlFor={`common-format-${doc.id}`}
                          className="text-base text-gray-800"
                        >
                          PDF
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id={`common-format-${doc.id}`}
                          className="mr-2"
                          onChange={() => {
                            handleDocumentCommonFormatChange(doc.id, "PNG");
                          }}
                          checked={documentCommonFormat[doc.id]?.includes(
                            "PNG"
                          )}
                        />
                        <label
                          htmlFor={`common-format-${doc.id}`}
                          className="text-base text-gray-800"
                        >
                          PNG
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id={`common-format-${doc.id}`}
                          className="mr-2"
                          onChange={() => {
                            handleDocumentCommonFormatChange(doc.id, "JPG");
                          }}
                          checked={documentCommonFormat[doc.id]?.includes(
                            "JPG"
                          )}
                        />
                        <label
                          htmlFor={`common-format-${doc.id}`}
                          className="text-base text-gray-800"
                        >
                          JPG
                        </label>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
              {/* <div className="p-1">
                <label
                  htmlFor={`processing_type`}
                  className="block text-base font-medium text-gray-800 mb-1"
                >
                  How should I process the documents?
                </label>
                <div className="flex items-center mb-2">
                  <input
                    type="checkbox"
                    id="batch_processing"
                    className="mr-2"
                  />
                  <label
                    htmlFor="batch_processing"
                    className="text-base text-gray-800"
                  >
                    Batch Processing
                  </label>
                </div>
                <div className="flex items-center mb-2">
                  <input
                    type="checkbox"
                    id="individual_processing"
                    className="mr-2"
                  />
                  <label
                    htmlFor="individual_processing"
                    className="text-base text-gray-800"
                  >
                    Individual Processing
                  </label>
                </div>
              </div> */}
              <div className="p-1">
                <label
                  htmlFor={`source`}
                  className="block text-sm font-medium text-muted-foreground mb-1"
                >
                  Source
                </label>
                <Select
                  value={documentsSource || ""}
                  onValueChange={(value) => handleSourceChange(value)}
                >
                  <SelectTrigger id={`source`} className="w-full">
                    <SelectValue placeholder="Select source" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="EMAIL">Email</SelectItem>
                    <SelectItem value="MANUAL_UPLOAD">Manual upload</SelectItem>
                  </SelectContent>
                </Select>
                {documentsSource === "EMAIL" && (
                  <div className="flex space-x-2 mt-2">
                    <button
                      onClick={() => setEmailConnectionSource("GMAIL")}
                      className={`flex items-center border rounded p-2 transition duration-200 ${
                        emailConnectionSource === "GMAIL"
                          ? "bg-blue-900 text-white"
                          : "bg-white text-gray-800"
                      } hover:bg-blue-800 hover:text-white`}
                    >
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/7/7e/Gmail_icon_%282020%29.svg"
                        alt="Gmail"
                        style={{
                          width: "40px",
                          height: "40px",
                          marginRight: "8px"
                        }}
                      />
                      Gmail
                    </button>
                    {/*<button
                      onClick={() => setEmailConnectionSource("OUTLOOK")}
                      className={`flex items-center border rounded p-2 transition duration-200 ${
                        emailConnectionSource === "OUTLOOK"
                          ? "bg-blue-900 text-white"
                          : "bg-white text-gray-800"
                      } hover:bg-blue-800 hover:text-white`}
                    >
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/d/df/Microsoft_Office_Outlook_%282018%E2%80%93present%29.svg"
                        alt="Outlook"
                        style={{
                          width: "40px",
                          height: "40px",
                          marginRight: "8px",
                        }}
                      />
                      Outlook
                    </button>*/}
                  </div>
                )}
              </div>
            </ul>
          </div>
          <div className="flex justify-end pt-4 border-t">
            <Button onClick={() => createNewFlow()} className="px-6">
              {creatingFlow ? t.flowCreation.creating : t.flowCreation.create}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog open={showFilePreview} onOpenChange={setShowFilePreview}>
        <DialogContent className="sm:max-w-[80vw] sm:max-h-[80vh] overflow-auto">
          <DialogHeader>
            <DialogTitle>
              {t.filePreview.title}: {previewFile?.name}
            </DialogTitle>
          </DialogHeader>
          {fieldsToExtract[selectedFile?.id]?.length > 0 && (
            <table className="min-w-full border-collapse border border-gray-300">
              <thead>
                <tr>
                  <th className="border border-gray-300 p-2 text-left text-xs">
                    Field Name
                  </th>
                  <th className="border border-gray-300 p-2 text-left text-xs">
                    Value
                  </th>
                </tr>
              </thead>
              <tbody>
                {fieldsToExtract[selectedFile.id].map((field, index) => (
                  <tr key={index}>
                    <td className="border border-gray-300 p-2 text-xs">
                      {field.name}
                    </td>
                    <td className="border border-gray-300 p-2 text-xs">
                      {field.value}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          <div className="mt-4">
            {previewFile?.exampleDocument?.type.includes("image/") ? (
              <img
                src={previewFile.exampleDocument.base64}
                alt={previewFile.name}
                className="max-w-full h-auto"
              />
            ) : previewFile?.exampleDocument?.type === "application/pdf" ? (
              <iframe
                src={`data:application/pdf;base64,${
                  previewFile.exampleDocument.base64.split(",")[1]
                }`}
                width="100%"
                height="600px"
                title={previewFile.name}
              />
            ) : (
              <div className="text-center p-4 bg-muted rounded-lg">
                <FileText className="w-12 h-12 mx-auto mb-2 text-muted-foreground" />
                <p>{t.filePreview.notAvailable}</p>
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </Card>
  );
}
