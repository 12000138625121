import axios from "axios";
import { backUrl } from "../utils/backURL";
const API_URL = backUrl;

export const uploadDocument = async (fileData) => {
  try {
    // Asegurarse de que el base64 tiene el formato correcto
    const base64Content = fileData.file_base64.includes("base64,")
      ? fileData.file_base64
      : `data:${fileData.file_type};base64,${fileData.file_base64}`;

    const requestData = {
      file: {
        file_name: fileData.file_name,
        file_base64: base64Content,
        file_type: fileData.file_type,
        file_size: fileData.file_size,
      },
    };

    console.log("Enviando datos:", {
      ...requestData,
      file: {
        ...requestData.file,
        file_base64: base64Content, // Para no llenar el log
      },
    });

    const response = await axios.post(
      `${API_URL}/businessFlows/ia/upload`,
      requestData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    if (error.response) {
      // El servidor respondió con un status code fuera del rango 2xx
      console.error("Error response:", {
        status: error.response.status,
        data: error.response.data,
        headers: error.response.headers,
      });
    }
    throw error;
  }
};

export const getBusinessFlowResults = async (mantisBusinessId, flowId) => {
  try {
    const response = await axios.post(
      `${API_URL}/businessFlows/getBusinessFlowResults`,
      { mantisBusinessId, flowId },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      console.error("Error response:", {
        status: error.response.status,
        data: error.response.data,
        headers: error.response.headers,
      });
    }
    throw error;
  }
};

export const exportFlowResults = async (mantisBusinessId, flowId) => {
  try {
    const response = await axios.post(
      `${API_URL}/exportFlowResults`,
      { mantisBusinessId, flowId },
      {
        headers: {
          "Content-Type": "application/json",
        },
        responseType: "blob", // Para manejar la respuesta como un archivo
      }
    );

    // Crear un enlace para descargar el archivo
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "resultados.xlsx");
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    if (error.response) {
      console.error("Error response:", {
        status: error.response.status,
        data: error.response.data,
        headers: error.response.headers,
      });
    }
    throw error;
  }
};
