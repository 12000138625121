import axios from "axios";
import { backUrl } from "../utils/backURL";
const API_URL = backUrl;

export const getMantisResponse = async (
  prompt,
  files = [],
  systemInstructions = ""
) => {
  try {
    const response = await axios.post(
      `${API_URL}/businessFlows/ia/generate`,
      {
        prompt,
        files,
        system_instructions: systemInstructions,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return {
      response: response.data.response,
      tokenCount: response.data.token_count,
      cost: response.data.cost,
      files: response.data.files,
    };
  } catch (error) {
    console.error("Error al comunicarse con Mantis IA:", error);
    throw error;
  }
};
