import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { Row, Table, Col, FormGroup, Spinner, Button, Alert } from "reactstrap";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./styles/businessBackofficeStyles.scss";

import {
  createMantisBusinessGroup,
  editMantisBusinessGroup,
  deleteMantisBusinessGroup,
  getMantisBusinessGroups,
  getMantisBusinessUsers,
  getSupervisors,
} from "../ApiCalls/mantisBusiness";

import { checkMantisBusinessUserPermission } from "../utils/businessUtils";

const select = (state) => ({
  userId: state.auth.userId,
  token: state.auth.token,
  mantisBusiness: state.auth.mantisBusiness,
  user: state.auth.user,
});

const selectStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "#333",
    borderColor: "#666",
    color: "white",
    fontFamily: "Poppins, sans-serif",
  }),
  menu: (styles) => ({
    ...styles,
    backgroundColor: "#333",
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isFocused ? "#555" : isSelected ? "#444" : "#333",
    color: "white",
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: "#555",
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    color: "white",
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: "white",
    ":hover": {
      backgroundColor: "#777",
      color: "white",
    },
  }),
  singleValue: (styles) => ({
    ...styles,
    color: "white",
  }),
};

function BusinessGroups(props) {
  const [mantisBusinessGroups, setMantisBusinessGroups] = useState([]);
  const [creatingNewGroup, setCreatingNewGroup] = useState(false);
  const [loadingCreatingNewGroup, setLoadingCreatingNewGroup] = useState(false);
  const [newGroupForm, setNewGroupForm] = useState({
    name: "",
    permissions: [],
    assignedUsers: [],
    supervisorId: "",
  });
  const [loading, setLoading] = useState(true);
  const [editingGroup, setEditingGroup] = useState(null);
  const [loadingEditingGroup, setLoadingEditingGroup] = useState(false);
  const [permissionsOptions, setPermissionsOptions] = useState([]);
  const [mantisBusinessUsers, setMantisBusinessUsers] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [errors, setErrors] = useState({});
  const [originalEditingGroup, setOriginalEditingGroup] = useState(null);
  const [hasPermission, setHasPermission] = useState(false);
  const [supervisors, setSupervisors] = useState([]);
  const [supervisorOptions, setSupervisorOptions] = useState([]);
  const [expandedPermissions, setExpandedPermissions] = useState(null);
  const [expandedUsers, setExpandedUsers] = useState(null);

  const getBusinessGroups = useCallback(async () => {
    try {
      const res = await getMantisBusinessGroups(
        props.mantisBusiness._id,
        props.token
      );
      if (res.success) {
        setMantisBusinessGroups(res.groups);
      } else {
        console.error("Error fetching groups:", res.message);
      }
    } catch (error) {
      console.error("Error fetching groups:", error);
    } finally {
      setLoading(false);
    }
  }, [props.mantisBusiness._id, props.token]);

  const getBusinessUsers = useCallback(async () => {
    try {
      const res = await getMantisBusinessUsers(
        props.mantisBusiness._id,
        props.token
      );
      if (res.success) {
        setMantisBusinessUsers(res.users);
        setUserOptions(
          res.users.map((user) => ({
            value: user._id,
            label: user.email,
          }))
        );
      } else {
        console.error("Error fetching users:", res.message);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  }, [props.mantisBusiness._id, props.token]);

  const getSupervisorsList = useCallback(async () => {
    try {
      const res = await getSupervisors(props.mantisBusiness._id, props.token);
      if (res.success) {
        const options = res.supervisors.map((supervisor) => ({
          value: supervisor._id,
          label: `${supervisor.firstName} ${supervisor.lastName} - ${supervisor.position}`,
        }));
        setSupervisorOptions(options);
      } else {
        console.error("Error fetching supervisors:", res.message);
      }
    } catch (error) {
      console.error("Error fetching supervisors:", error);
    }
  }, [props.mantisBusiness._id, props.token]);

  useEffect(() => {
    const canViewGroups =
      props.user.mainUser ||
      checkMantisBusinessUserPermission(props.user, "VIEW_GROUPS") ||
      checkMantisBusinessUserPermission(props.user, "UPDATE_GROUPS") ||
      checkMantisBusinessUserPermission(props.user, "DELETE_GROUPS") ||
      checkMantisBusinessUserPermission(props.user, "CREATE_GROUPS");
    setHasPermission(canViewGroups);

    if (canViewGroups) {
      getBusinessGroups();
      getBusinessUsers();
      getSupervisorsList();
      setPermissionsOptions(
        props.mantisBusiness.enabledPermissions.map((permission) => ({
          value: permission.permission,
          label: permission.friendlyName,
        }))
      );
    }
  }, [
    props.user,
    props.mantisBusiness.enabledPermissions,
    getBusinessGroups,
    getBusinessUsers,
    getSupervisorsList,
  ]);

  useEffect(() => {
    const loadSupervisors = async () => {
      try {
        const res = await getSupervisors(props.mantisBusiness._id, props.token);
        if (res.success) {
          setSupervisors(res.supervisors);
          setSupervisorOptions(
            res.supervisors.map((supervisor) => ({
              value: supervisor._id,
              label: `${supervisor.firstName} ${supervisor.lastName} - ${supervisor.position}`,
              original: supervisor,
            }))
          );
        } else {
          toast.error("Error al cargar supervisores: " + res.message);
        }
      } catch (error) {
        console.error("Error loading supervisors:", error);
        toast.error("Error al cargar supervisores");
      }
    };

    if (props.mantisBusiness._id && props.token) {
      loadSupervisors();
    }
  }, [props.mantisBusiness._id, props.token]);

  useEffect(() => {
    if (newGroupForm.name.length > 0) {
      setErrors({});
    } else {
      setErrors((prev) => ({
        ...prev,
        name: "El nombre del grupo es requerido",
      }));
    }
  }, [newGroupForm]);

  const validateForm = (form) => {
    const newErrors = {};
    if (!form.name) newErrors.name = "Debe ingresar un nombre para el grupo";
    if (!form.permissions || form.permissions.length === 0)
      newErrors.permissions = "Debe seleccionar al menos un permiso";
    if (!form.assignedUsers || form.assignedUsers.length === 0)
      newErrors.assignedUsers = "Debe asignar al menos un usuario";

    if (form === newGroupForm) {
      if (!form.supervisorId)
        newErrors.supervisor = "Debe seleccionar un supervisor";
    } else {
      if (!form.supervisor?._id)
        newErrors.supervisor = "Debe seleccionar un supervisor";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleCreateNewGroup = async (event) => {
    event.preventDefault();
    if (!validateForm(newGroupForm)) return;

    setLoadingCreatingNewGroup(true);
    try {
      const assignedUsers = newGroupForm.assignedUsers.map((userId) => {
        const user = mantisBusinessUsers.find((u) => u._id === userId);
        return {
          userId: user._id,
          userName: user.email,
        };
      });
      const res = await createMantisBusinessGroup(
        props.mantisBusiness._id,
        props.token,
        { ...newGroupForm, assignedUsers },
        newGroupForm.supervisorId
      );
      if (res.success) {
        setMantisBusinessGroups([...mantisBusinessGroups, res.group]);
        setCreatingNewGroup(false);
        setNewGroupForm({
          name: "",
          permissions: [],
          assignedUsers: [],
          supervisorId: "",
        });
        toast.success("Grupo creado exitosamente");
      } else {
        toast.error(
          "Error al crear el grupo: " + (res.message || "Error desconocido")
        );
      }
    } catch (error) {
      console.error("Error creating group:", error);
      toast.error(
        "Error al crear el grupo: " + (error.message || "Error desconocido")
      );
    } finally {
      setLoadingCreatingNewGroup(false);
    }
  };

  const handleEditGroup = async (event) => {
    event.preventDefault();
    if (!validateForm(editingGroup)) return;

    if (JSON.stringify(editingGroup) === JSON.stringify(originalEditingGroup)) {
      toast.warning("No se han realizado cambios en el grupo");
      return;
    }

    if (window.confirm("¿Está seguro de que desea realizar estos cambios?")) {
      setLoadingEditingGroup(true);
      try {
        const groupData = {
          name: editingGroup.name,
          permissions: editingGroup.permissions,
        };

        const assignedUsers = editingGroup.assignedUsers.map(
          (user) => user.userId
        );

        const res = await editMantisBusinessGroup(
          props.mantisBusiness._id,
          editingGroup._id,
          groupData,
          editingGroup.supervisor?._id,
          assignedUsers,
          props.token
        );

        if (res.success) {
          toast.success("Grupo actualizado exitosamente");
          const updatedGroups = mantisBusinessGroups.map((group) =>
            group._id === res.group._id ? res.group : group
          );
          setMantisBusinessGroups(updatedGroups);
          setEditingGroup(null);
          setOriginalEditingGroup(null);
        } else {
          toast.error(
            "Error al editar el grupo: " + (res.message || "Error desconocido")
          );
        }
      } catch (error) {
        console.error("Error editing group:", error);
        toast.error(
          "Error al editar el grupo: " + (error.message || "Error desconocido")
        );
      } finally {
        setLoadingEditingGroup(false);
      }
    }
  };

  const setEditingGroupWithOriginal = (group) => {
    console.log("Editing group:", group);
    setEditingGroup(group);
    setOriginalEditingGroup(JSON.parse(JSON.stringify(group)));
  };

  async function handleDeleteGroup(groupId) {
    if (window.confirm("¿Está seguro de que desea eliminar este grupo?")) {
      try {
        const res = await deleteMantisBusinessGroup(
          props.mantisBusiness._id,
          groupId,
          props.token
        );
        if (res.success) {
          setMantisBusinessGroups(
            mantisBusinessGroups.filter((group) => group._id !== groupId)
          );
          toast.success("Grupo eliminado exitosamente");
        } else {
          toast.error(
            "Error al eliminar el grupo: " +
              (res.message || "Error desconocido")
          );
        }
      } catch (error) {
        console.error("Error deleting group:", error);
        toast.error(
          "Error al eliminar el grupo: " +
            (error.message || "Error desconocido")
        );
      }
    }
  }

  function handleInputChange(event, setStateFunction) {
    setStateFunction((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  }

  const getPermissionFriendlyName = (permissionName) => {
    const permission = permissionsOptions.find(
      (p) => p.value === permissionName
    );
    return permission ? permission.label : permissionName;
  };

  const getSupervisorName = (supervisor) => {
    if (!supervisor) return "No asignado";
    return `${supervisor.firstName} ${supervisor.lastName} - ${supervisor.position}`;
  };

  const formatPermissions = (permissions, groupId) => {
    if (!permissions || permissions.length === 0) return "-";

    const formattedPermissions = permissions.map((p) => {
      const permission = typeof p === "string" ? p : p.permission;
      const foundPermission = props.mantisBusiness.enabledPermissions.find(
        (ep) => ep.permission === permission
      );
      return foundPermission ? foundPermission.friendlyName : permission;
    });

    const isExpanded = expandedPermissions === groupId;

    return (
      <div className="permissions-cell">
        <div className={`permissions-list ${isExpanded ? "expanded" : ""}`}>
          {isExpanded ? (
            formattedPermissions.map((permission, index) => (
              <div key={index} className="permission-item">
                {permission}
              </div>
            ))
          ) : (
            <>
              {formattedPermissions.slice(0, 2).join(", ")}
              {formattedPermissions.length > 2 && "..."}
            </>
          )}
        </div>
        {formattedPermissions.length > 2 && (
          <div
            className="expand-button"
            onClick={(e) => {
              e.stopPropagation();
              setExpandedPermissions(isExpanded ? null : groupId);
            }}
          >
            {isExpanded ? "Ver menos" : "Ver más"}
          </div>
        )}
      </div>
    );
  };

  const formatUsers = (users, groupId) => {
    if (!users || users.length === 0) return "-";

    const isExpanded = expandedUsers === groupId;

    return (
      <div className="users-cell">
        <div className={`users-list ${isExpanded ? "expanded" : ""}`}>
          {isExpanded ? (
            users.map((user, index) => (
              <div key={index} className="user-item">
                {user.userName}
              </div>
            ))
          ) : (
            <>
              {users
                .slice(0, 2)
                .map((u) => u.userName)
                .join(", ")}
              {users.length > 2 && "..."}
            </>
          )}
        </div>
        {users.length > 2 && (
          <div
            className="expand-button"
            onClick={(e) => {
              e.stopPropagation();
              setExpandedUsers(isExpanded ? null : groupId);
            }}
          >
            {isExpanded ? "Ver menos" : "Ver más"}
          </div>
        )}
      </div>
    );
  };

  if (!hasPermission) {
    return (
      <Alert color="danger" className="mt-3">
        No tienes permiso para ver esta página.
      </Alert>
    );
  }

  return (
    <div>
      <Row>
        <Col xs={11} style={{ margin: "auto" }}>
          <Row>
            <Col xs={6}>
              {!creatingNewGroup && !editingGroup && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <div
                    className="add-employee-button"
                    onClick={() => setCreatingNewGroup(true)}
                  >
                    +
                  </div>
                  <h5 className="add-employee-text">Crear grupo</h5>
                </div>
              )}
              {(creatingNewGroup || editingGroup) && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <div
                    className="add-employee-button"
                    onClick={() => {
                      setCreatingNewGroup(false);
                      setEditingGroup(null);
                    }}
                  >
                    {"<"}
                  </div>
                  <h5 className="add-employee-text">Atras</h5>
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col xs={11} style={{ margin: "auto" }}>
          <div>
            {loading ? (
              <Spinner color="white" />
            ) : (
              <>
                {!creatingNewGroup &&
                  !editingGroup &&
                  mantisBusinessGroups.length > 0 && (
                    <div className="users-list-container">
                      <Table>
                        <thead>
                          <tr>
                            <th>Nombre</th>
                            <th>Permisos</th>
                            <th>Fecha de creación</th>
                            <th>Usuarios</th>
                            <th>Supervisor</th>
                            <th>Acciones</th>
                          </tr>
                        </thead>
                        <tbody>
                          {mantisBusinessGroups.map((group) => (
                            <tr key={group._id}>
                              <td>{group.name}</td>
                              <td className="permissions-column">
                                {formatPermissions(
                                  group.permissions,
                                  group._id
                                )}
                              </td>
                              <td>
                                {new Date(
                                  group.dateCreated
                                ).toLocaleDateString()}
                              </td>
                              <td>
                                {formatUsers(group.assignedUsers, group._id)}
                              </td>
                              <td>{getSupervisorName(group.supervisor)}</td>
                              <td>
                                <div className="user-actions">
                                  <Button
                                    className="action-button edit"
                                    onClick={() =>
                                      setEditingGroupWithOriginal(group)
                                    }
                                  >
                                    <i className="fas fa-edit"></i>
                                  </Button>
                                  <Button
                                    className="action-button delete"
                                    onClick={() => handleDeleteGroup(group._id)}
                                  >
                                    <i className="fas fa-trash-alt"></i>
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  )}
                {(creatingNewGroup || editingGroup) && (
                  <Form
                    onSubmit={
                      creatingNewGroup ? handleCreateNewGroup : handleEditGroup
                    }
                  >
                    <h3
                      style={{
                        textAlign: "left",
                        marginBottom: "20px",
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "20px",
                        fontWeight: "500",
                        color: "white",
                      }}
                    >
                      {creatingNewGroup ? "Crear Nuevo Grupo" : "Editar Grupo"}
                    </h3>

                    {/* Nombre del grupo */}
                    <FormGroup>
                      <Form.Label>Nombre del Grupo</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        value={
                          editingGroup ? editingGroup.name : newGroupForm.name
                        }
                        onChange={(e) =>
                          handleInputChange(
                            e,
                            editingGroup ? setEditingGroup : setNewGroupForm
                          )
                        }
                        isInvalid={!!errors.name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.name}
                      </Form.Control.Feedback>
                    </FormGroup>

                    {/* Selector de Supervisor */}
                    <FormGroup>
                      <h4
                        style={{
                          textAlign: "left",
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: "400",
                          fontSize: "18px",
                          color: "white",
                        }}
                      >
                        Supervisor
                      </h4>
                      <Select
                        options={supervisorOptions}
                        value={supervisorOptions.find((option) =>
                          editingGroup
                            ? option.value === editingGroup.supervisor?.userId
                            : option.value === newGroupForm.supervisorId
                        )}
                        onChange={(selectedOption) => {
                          if (editingGroup) {
                            setEditingGroup((prev) => ({
                              ...prev,
                              supervisor: selectedOption
                                ? supervisors.find(
                                    (s) => s._id === selectedOption.value
                                  )
                                : null,
                            }));
                          } else {
                            setNewGroupForm((prev) => ({
                              ...prev,
                              supervisorId: selectedOption
                                ? selectedOption.value
                                : null,
                            }));
                          }
                        }}
                        placeholder="Seleccionar supervisor..."
                        isClearable
                        styles={selectStyles}
                      />
                      {errors.supervisor && (
                        <div style={{ color: "red", marginTop: "5px" }}>
                          {errors.supervisor}
                        </div>
                      )}
                    </FormGroup>

                    {/* Permisos */}
                    <FormGroup>
                      <h4
                        style={{
                          textAlign: "left",
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: "400",
                          fontSize: "18px",
                          color: "white",
                        }}
                      >
                        Permisos
                      </h4>
                      <Select
                        isMulti
                        options={permissionsOptions}
                        value={permissionsOptions.filter((option) =>
                          editingGroup
                            ? editingGroup.permissions.includes(option.value)
                            : newGroupForm.permissions.includes(option.value)
                        )}
                        onChange={(selectedOptions) => {
                          const permissions = selectedOptions.map(
                            (option) => option.value
                          );
                          if (editingGroup) {
                            setEditingGroup((prev) => ({
                              ...prev,
                              permissions,
                            }));
                          } else {
                            setNewGroupForm((prev) => ({
                              ...prev,
                              permissions,
                            }));
                          }
                        }}
                        placeholder="Seleccionar permisos..."
                        styles={selectStyles}
                      />
                      {errors.permissions && (
                        <div style={{ color: "red", marginTop: "5px" }}>
                          {errors.permissions}
                        </div>
                      )}
                    </FormGroup>

                    {/* Usuarios Asignados */}
                    <FormGroup>
                      <h4
                        style={{
                          textAlign: "left",
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: "400",
                          fontSize: "18px",
                          color: "white",
                        }}
                      >
                        Usuarios Asignados
                      </h4>
                      <Select
                        isMulti
                        options={userOptions}
                        value={userOptions.filter((option) =>
                          editingGroup
                            ? editingGroup.assignedUsers
                                .map((user) => user.userId)
                                .includes(option.value)
                            : newGroupForm.assignedUsers.includes(option.value)
                        )}
                        onChange={(selectedOptions) => {
                          let assignedUsers = [];
                          if (!editingGroup) {
                            assignedUsers = selectedOptions.map(
                              (option) => option.value
                            );
                          } else {
                            assignedUsers = selectedOptions.map((option) => {
                              const user = mantisBusinessUsers.find(
                                (u) => u._id === option.value
                              );
                              return {
                                userId: user._id,
                                userName: user.email,
                              };
                            });
                          }
                          console.log("Assigned users:", assignedUsers);
                          if (editingGroup) {
                            setEditingGroup((prev) => ({
                              ...prev,
                              assignedUsers,
                            }));
                          } else {
                            setNewGroupForm((prev) => ({
                              ...prev,
                              assignedUsers,
                            }));
                          }
                        }}
                        placeholder="Seleccionar usuarios..."
                        styles={selectStyles}
                      />
                      {errors.assignedUsers && (
                        <div style={{ color: "red", marginTop: "5px" }}>
                          {errors.assignedUsers}
                        </div>
                      )}
                    </FormGroup>

                    {/* Botones de acción */}
                    <div className="form-actions">
                      <Button
                        type="submit"
                        className="action-button submit"
                        disabled={loadingEditingGroup}
                      >
                        {loadingEditingGroup ? (
                          <Spinner size="sm" />
                        ) : editingGroup ? (
                          "Guardar Cambios"
                        ) : (
                          "Crear Grupo"
                        )}
                      </Button>
                      <Button
                        className="action-button cancel"
                        onClick={() => {
                          setEditingGroup(null);
                          setOriginalEditingGroup(null);
                          setErrors({});
                          setCreatingNewGroup(false);
                        }}
                      >
                        Cancelar
                      </Button>
                    </div>
                  </Form>
                )}
              </>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default connect(select)(BusinessGroups);
