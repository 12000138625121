import axios from "axios";
import { backUrl } from "../utils/backURL";
const API_URL = backUrl;

export const fetchFlows = async () => {
  try {
    const url = `${API_URL}/businessFlows/getBusinessFlows`;
    console.log("Request URL:", url);

    const response = await axios.post(url, {
      mantisBusinessId: "672e82bf3ab5c40012235316",
    });
    console.log("Response:", response);
    return response.data.flows;
  } catch (error) {
    console.error(
      "Error al obtener los flujos:",
      error.response?.data || error
    );
    throw error;
  }
};

export const createFlow = async (flowData) => {
  try {
    const url = `${API_URL}/businessFlows/createBusinessFlow`;
    console.log("Request URL:", url);

    const response = await axios.post(url, {
      name: flowData.name,
      description: flowData.description,
      mantisBusinessId: flowData.mantisBusinessId,
      mantisBusinessUserId: flowData.mantisBusinessUserId,
      fieldsToExtract: flowData.fieldsToExtract,
      flowDocuments: flowData.flowDocuments,
      conditionalFields: flowData.conditionalFields,
      documentCommonName: flowData.documentCommonName,
      documentCommonFormat: flowData.documentCommonFormat,
      calculatedFields: flowData.calculatedFields,
      documentFlowName: flowData.documentFlowName,
      sources: flowData.sources,
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error al crear el flujo de negocio:",
      error.response?.data || error
    );
    throw error;
  }
};

export const deleteFlow = async (flowId, mantisBusinessId) => {
  try {
    const url = `${API_URL}/businessFlows/deleteBusinessFlow`;
    console.log("Request URL:", url);

    const response = await axios.post(url, {
      flowId: flowId,
      mantisBusinessId: mantisBusinessId,
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error al eliminar el flujo de negocio:",
      error.response?.data || error
    );
    throw error;
  }
};

export const updateFlow = async (flowData) => {
  try {
    const url = `${API_URL}/businessFlows/updateBusinessFlow`;
    console.log("Request URL:", url);

    const response = await axios.post(url, {
      flowId: flowData.id,
      name: flowData.name,
      description: flowData.description,
      mantisBusinessId: "672e82bf3ab5c40012235316", // Hardcodeado por ahora
    });
    return response.data;
  } catch (error) {
    console.error("Error al editar el flujo:", error.response?.data || error);
    throw error;
  }
};

export const saveFlowDocument = async (flowData) => {
  try {
    const url = `${API_URL}/businessFlows/saveFlowDocument`;
    console.log("Request URL:", url);

    const response = await axios.post(url, {
      flowId: flowData.flowId,
      document: {
        name: flowData.document.name,
        description: flowData.document.description,
        mantisBusinessId: "672e82bf3ab5c40012235316",
        exampleDocument: {
          base64: flowData.document.exampleDocument.base64,
          name: flowData.document.exampleDocument.name,
          remoteFile: flowData.document.exampleDocument.remoteFile,
          type: flowData.document.exampleDocument.type,
        },
      },
      mantisBusinessId: "672e82bf3ab5c40012235316",
      isNew: flowData.isNew,
      fieldsToExtract: flowData.fieldsToExtract.map((field) => ({
        name: field.name,
        flowId: flowData.flowId,
        mantisBusinessId: "672e82bf3ab5c40012235316",
        nameInDocument: field.nameInDocument,
        format: field.format,
        position: field.position,
        additionalDetails: field.additionalDetails,
      })),
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error al guardar el documento del flujo:",
      error.response?.data || error
    );
    throw error;
  }
};

export const saveFlowConditionalFields = async (flowData) => {
  try {
    const url = `${API_URL}/businessFlows/saveFlowConditionalFields`;
    console.log("Request URL:", url);

    const response = await axios.post(url, {
      flowId: flowData.flowId,
      conditionalFields: flowData.conditionalFields.map((field) => ({
        name: field.name,
        flowFieldToCompareId: field.flowFieldToCompareId,
        comparedWith: {
          type: field.comparedWith.type,
          value: field.comparedWith.value,
          fieldId: field.comparedWith.fieldId,
        },
        operator: field.operator,
        valueIfTrue: field.valueIfTrue,
        valueIfFalse: field.valueIfFalse,
      })),
      mantisBusinessId: "672e82bf3ab5c40012235316",
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error al guardar los campos condicionales:",
      error.response?.data || error
    );
    throw error;
  }
};

export const deleteFlowConditionalField = async (fieldId) => {
  try {
    const url = `${API_URL}/businessFlows/deleteFlowConditionalField`;
    console.log("Request URL:", url);

    const response = await axios.post(url, {
      fieldId: fieldId,
      mantisBusinessId: "672e82bf3ab5c40012235316",
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error al eliminar el campo condicional:",
      error.response?.data || error
    );
    throw error;
  }
};

export const runManualFlow = async (flowId, mantisBusinessId, files) => {
  try {
    const url = `${API_URL}/businessFlows/runManualFlow`;
    const response = await axios.post(url, {
      flowId: flowId,
      mantisBusinessId: mantisBusinessId,
      files: files,
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error al ejecutar el flujo manual:",
      error.response?.data || error
    );
    throw error;
  }
};
